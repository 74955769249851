import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import virtual from "../../assets/images/blogs/virtual.png";

const Virtualcpaservices = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
      <style jsx>{`
  h4 {
    font-size: 18px;
  }
  h3 {
    font-size: 28px;
  }
  span {
    font-size: 16px;
  }
  p {
    font-size: 16px;
  }
  li {
    font-size: 16px;
    font-weight: 500;
  }
  .mainCard {
    height: 20rem;
  }
  .circle {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
  }
  i {
    color: #072f5f;
  }
  ul {
    padding-left: 17px;
  }
  @media (max-width: 574px) {
    .mTop {
      margin-top: 30px;
    }
    p {
      font-size: 18px;
    }
    .img-fluid {
      min-height: 100px;
    }
    .container-fluid {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @media (min-width: 992px) {
    /* for banner image */
    .bmTop {
      margin-top: 130px;
    }
  }
  @media (max-width: 574px) {
    .banner-image {
      margin-top: 70px; /* Adjust this value for mobile view */
    }
  }
`}</style>
      <section>
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold", marginTop:'10px' }}
            >
              Virtual CPA Services
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Introduction
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Virtual CPA Services: How to Maximize Your Business's
                        Financial Health Remotely
                      </span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      In today’s fast-changing modern digital world, financial
                      management is undergoing through a transformational shift.
                    </p>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      As we enter 2025, small businesses are increasingly
                      adopting virtual CPA services to streamline operations and
                      improve financial health. At Boston Financial Advisory
                      Group, we are at the forefront of this revolution and lead
                      the way by providing cutting-edge solutions blended with
                      expertise and technology.{" "}
                    </p>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        The Rise of Virtual CPA Services
                      </span>
                    </div>
                  </div>
                  <p
                    className="text-dark servicep"
                    style={{ textAlign: "justify" }}
                  >
                    Virtual CPA services have become a game-changer for small
                    businesses. By leveraging cloud-based technologies and AI in
                    accounting, CPAs can now provide full financial support
                    remotely. This shift not only offers flexibility like never
                    before but also opens up possibilities to a much broader
                    range of expertise.
                  </p>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Benefits of Going Virtual with Your CPA{" "}
                      </span>
                    </div>
                  </div>
                  <div
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li className="mt-2">
                      Cost-Effective: Virtual services eliminate the need for a
                      physical office space, saving overhead costs. Flexibility:
                      Access your financial data and speak with your CPA at any
                      time and from anywhere.{" "}
                    </li>
                    <li className="mt-2">
                      Expertise on Demand: Connect with specialized knowledge,
                      whether you need a small business CPA or a cryptocurrency
                      tax guidance.{" "}
                    </li>
                    <li className="mt-2">
                      Enhanced Efficiency: AI-powered tools automate routine
                      tasks, enabling you to focus on strategic financial
                      planning.{" "}
                    </li>
                    <li className="mt-2">
                      Maximizing Your Business’s Financial Health Remotely{" "}
                    </li>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        To fully harness the power of virtual CPA services,
                        consider these strategies:
                      </span>
                    </div>
                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                        1. <b>Embrace Cloud-Based Accounting Solutions:</b>{" "}
                        Cloud-based platforms enable for real-time collaboration
                        between you and your CPA. This ensures your financial
                        data is always up-to-date and accessible.
                      </li>
                      <li className="mt-2">
                        2. <b>Leverage AI for Smarter Financial Decisions:</b>{" "}
                        AI in accounting isn’t just a buzzword; it’s a powerful
                        tool that can analyze vast amounts of data to provide
                        insights and forecasts, helping you make informed
                        decisions about your business’s future.
                      </li>
                      <li className="mt-2">
                        3.{" "}
                        <b>
                          {" "}
                          Stay Ahead with Proactive Tax Planning Strategies:{" "}
                        </b>{" "}
                        As we approach 2025, tax laws continue to evolve. A
                        virtual CPA can help you stay ahead of the curve,
                        implementing tax planning strategies that maximize your
                        savings and ensure compliance.
                      </li>
                      <li className="mt-2">
                        4.{" "}
                        <b>
                          {" "}
                          Navigate the Complexities of Cryptocurrency Taxes:{" "}
                        </b>{" "}
                        If your business deals with digital currencies, you need
                        a specialized CPA for cryptocurrency taxes. Virtual CPAs
                        specializing in this area can help you navigate the
                        complex and ever-changing landscape of crypto taxation.
                      </li>
                    </div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Why Choose Boston Financial Advisory Group for Virtual
                        CPA Services?
                      </span>
                    </div>
                  </div>

                  <img
                    src={virtual}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "350px",
                      height: "270px",
                    }}
                  />

                  <p
                    className="text-dark servicep mt-4"
                    style={{ textAlign: "justify" }}
                  >
                    At Boston Financial Advisory Group, years of experience and
                    leading-edge technology combine to offer you the best
                    virtual CPA services. Our small business CPAs understand the
                    unique challenges that come with being an entrepreneur and a
                    startup.
                  </p>

                  <p
                    className="text-dark servicep mt-4"
                    style={{ textAlign: "justify" }}
                  >
                    We offer tailored tax planning strategies for 2025 and
                    beyond, expertise in cryptocurrency taxes, AI-powered
                    financial analysis and forecasting, and comprehensive
                    virtual accounting solutions for businesses of all sizes.
                  </p>

                  <p
                    className="text-dark servicep mt-4"
                    style={{ textAlign: "justify" }}
                  >
                    Don't let distance stand in the way of accessing to
                    first-class financial expertise. Step into the future of
                    accounting with Boston Financial Advisory Group's virtual
                    CPA services. Contact us today to find out how we can help
                    your business achieve maximum financial health remotely.{" "}
                  </p>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Virtualcpaservices;
