import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import cookies from '../assets/images/NewIcon/cookies.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CookiesConsent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [consentBannerVisible, setConsentBannerVisible] = useState(
    !localStorage.getItem('acceptedCookies') && !localStorage.getItem('rejectedCookies')
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [preferences, setPreferences] = useState({
    essentials: true,
    advertising: false,
    analytics: false,
    social: false,
    unclassified: false,
  });
  const [hoveredButton, setHoveredButton] = useState(null);

  const acceptCookies = () => {
    localStorage.setItem('acceptedCookies', JSON.stringify(preferences));
    setConsentBannerVisible(false);
    setDialogOpen(false);
  };

  const rejectCookies = () => {
    localStorage.setItem('rejectedCookies', true);
    setConsentBannerVisible(false);
    setDialogOpen(false);
  };

  const handleButtonClick = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handlePreferenceChange = (event) => {
    setPreferences({
      ...preferences,
      [event.target.name]: event.target.checked,
    });
  };

  // Define mobile-specific styles while keeping desktop styles intact.
  const containerStyle = isMobile
    ? {
        ...styles.container,
        flexDirection: 'column',
        textAlign: 'center',
        padding: '15px',
      }
    : styles.container;

  const textStyle = isMobile
    ? {
        ...styles.text,
        fontSize: '14px',
      }
    : styles.text;

  const buttonsStyle = isMobile
    ? {
        ...styles.buttons,
        flexDirection: 'column',
        width: '100%',
      }
    : styles.buttons;

  const buttonStyle = isMobile
    ? {
        ...styles.button,
        width: '100%',
        padding: '12px',
        fontSize: '16px',
      }
    : styles.button;

  return (
    <>
      <button
        className="fixed-button"
        onClick={handleButtonClick}
        style={styles.fixedButton}
      >
        <img
          src={cookies}
          className="icon-small"
          alt="Cookies"
          height={50}
          width={50}
        />
      </button>

      {consentBannerVisible && (
        <div style={containerStyle}>
          <span style={textStyle}>
            We use essential cookies to make our site work. With your consent, we may also use non-essential cookies to improve user experience and analyze website traffic. By clicking "Accept" you agree to our website's cookie use as described in our Cookie Policy. You can change your cookie settings at any time by clicking "Preferences".
          </span>
          <div style={buttonsStyle}>
            <button
              onClick={acceptCookies}
              onMouseEnter={() => setHoveredButton('accept')}
              onMouseLeave={() => setHoveredButton(null)}
              style={{
                ...buttonStyle,
                ...(hoveredButton === 'accept' ? styles.buttonHover : {}),
              }}
            >
              Accept All
            </button>
            <button
              onClick={rejectCookies}
              onMouseEnter={() => setHoveredButton('reject')}
              onMouseLeave={() => setHoveredButton(null)}
              style={{
                ...buttonStyle,
                ...(hoveredButton === 'reject' ? styles.buttonHover : {}),
              }}
            >
              Reject All
            </button>
            <button
              onClick={handleButtonClick}
              onMouseEnter={() => setHoveredButton(true)}
              onMouseLeave={() => setHoveredButton(false)}
              style={{
                backgroundColor: 'transparent',
                border: '2px solid #070f5f',
                color: hoveredButton ? '#fff' : '#000',
                backgroundColor: hoveredButton ? '#072f5f' : '#fff',
                padding: '10px 20px',
                cursor: 'pointer',
              }}
            >
              Cookie Concern
            </button>
          </div>
          <button onClick={() => setConsentBannerVisible(false)} style={styles.closeButton}>
            X
          </button>
        </div>
      )}

      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        // Only apply full-screen settings on mobile
        fullScreen={isMobile}
        fullWidth={isMobile}
        maxWidth={isMobile ? false : 'sm'}
        sx={isMobile ? {
          '& .MuiDialog-paper': {
            borderRadius: 0,
          },
        } : {}}
      >
        <DialogTitle>Cookie Preferences</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            We use essential cookies to make our site work. With your consent, we may also use non-essential cookies to improve user experience and analyze website traffic. By clicking "Accept" you agree to our website's cookie use as described in our Cookie Policy. You can change your cookie settings at any time by clicking "Preferences".
            <h3 style={{ fontSize: '23px', marginTop: '12px', color: 'rgb(7, 47, 95)', fontWeight: 600 }}>
              Preferences
            </h3>
            <FormGroup>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Essentials</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Essential cookies are necessary for the website to function properly. They include basic functionalities like security, network management, and accessibility. You may disable these by changing your browser settings, but this may affect how the website functions.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Advertising</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Advertising cookies are used to deliver advertisements that are relevant to you. They remember your web browsing activity and may be used to build a profile of your interests.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Analytics and Customization</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    These cookies collect information about how you use the website, such as which pages you visit and if you experience any errors. They help us improve the performance of the site and your experience.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Social Networking</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Social networking cookies allow you to share content from our website on social media platforms and track your engagement with that content.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Unclassified</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Unclassified cookies are those that are still in the process of being classified, together with the providers of individual cookies.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </FormGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={acceptCookies} style={buttonStyle}>
            Accept All
          </Button>
          <Button onClick={rejectCookies} style={buttonStyle}>
            Reject All
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const styles = {
  container: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    background: '#E0FBE2',
    padding: '20px',
    boxShadow: '0 -2px 5px rgba(0,0,0,0.3)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1000,
    border: 'solid 2px',
  },
  text: {
    flex: 1,
    marginRight: '10px',
    textAlign: 'left',
  },
  buttons: {
    display: 'flex',
    gap: '10px',
  },
  button: {
    padding: '10px 20px',
    background: 'rgb(7, 47, 95)',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    transition: 'background 0.3s, color 0.3s',
  },
  buttonHover: {
    background: 'black',
    color: 'white',
  },
  fixedButton: {
    position: 'fixed',
    bottom: '50px',
    left: '10px',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    fontSize: '24px',
    cursor: 'pointer',
    zIndex: 2000,
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
  },
};

export default CookiesConsent;
