import React from 'react';
import blog from '../../../assets/images/blogs/blog.png'
import videoBackground from "../../../assets/video/boston baneer video.mp4";
import BlogTwo from '../../BlogTwo';
 

const Blogs = () => {

  return (
    <section>
          <BlogTwo/>
    </section>
  );
};

export default Blogs;
