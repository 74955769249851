import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import newblogimg from '../../assets/images/blogs/newblogimg.png'

const Decodingindiaconsumptionboom = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
       <style jsx>{`
  h4 {
    font-size: 18px;
  }
  h3 {
    font-size: 28px;
  }
  span {
    font-size: 16px;
  }
  p {
    font-size: 16px;
  }
  li {
    font-size: 16px;
    font-weight: 500;
  }
  .mainCard {
    height: 20rem;
  }
  .circle {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
  }
  i {
    color: #072f5f;
  }
  ul {
    padding-left: 17px;
  }
  @media (max-width: 574px) {
    .mTop {
      margin-top: 30px;
    }
    p {
      font-size: 18px;
    }
    .img-fluid {
      min-height: 100px;
    }
    .container-fluid {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @media (min-width: 992px) {
    /* for banner image */
    .bmTop {
      margin-top: 130px;
    }
  }
  @media (max-width: 574px) {
    .banner-image {
      margin-top: 70px; /* Adjust this value for mobile view */
    }
  }
`}</style>

      <section>
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold", marginTop:'10px' }}
            >
              Decoding India's Consumption Boom
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Introduction
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Decoding India's Consumption Boom: BFAG's Investment
                        Approaches to the World's Fastest-Growing Major Economy
                      </span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      As we approach the year 2025, India is a symbol of growth
                      in the global economy. With its high GDP growth and
                      changing consumption patterns, the nation offers a wealth
                      of opportunities for Indian and American investors. At
                      Boston Financial Advisory Group (BFAG), we have crafted
                      strategic insights to aid our clients in accessing this
                      economic giant.
                    </p>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        India's Economic Path: A Picture
                      </span>
                    </div>
                  </div>
                  <p
                    className="text-dark servicep"
                    style={{ textAlign: "justify" }}
                  >
                    India's economy is projected to grow at an impressive 6.6%
                    in 2025, surpassing that of many major economies. This
                    growth is supported by several factors:
                  </p>
                  <p
                    className="text-dark servicep"
                    style={{ textAlign: "justify" }}
                  >
                    Rising Middle Class: By 2025, India's middle class will
                    contribute 59% of the nation's consumer spend.
                  </p>
                  <p
                    className="text-dark servicep"
                    style={{ textAlign: "justify" }}
                  >
                    Digital Revolution: With more than 800 million internet
                    users, India's digital economy is transforming consumer
                    habits.
                  </p>
                  <p
                    className="text-dark servicep"
                    style={{ textAlign: "justify" }}
                  >
                    Urbanization: Fast-tracked urbanization is bringing forth
                    new consumption centers beyond traditional metropolitan
                    cities.
                  </p>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Changing Consumption Patterns
                      </span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      It is imperative that investors grasp India's evolving
                      consumption landscape:{" "}
                    </p>
                  </div>
                  <div
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li className="mt-2">
                      Premiumization: Indian consumers are increasingly turning
                      to higher-quality, premium offerings across categories.
                    </li>
                    <li className="mt-2">
                      E-commerce Boom: The Indian e-commerce market is projected
                      to hit $200 billion by 2026, growing at a 21% rate
                      annually.
                    </li>
                    <li className="mt-2">
                      Enhanced Efficiency: AI-powered tools automate routine
                      tasks, enabling you to focus on strategic financial
                      planning.{" "}
                    </li>
                    <li className="mt-2">
                      Rural Consumption: The proportion of rural India in total
                      consumption is projected to increase to 45% by 2025.{" "}
                    </li>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Investment Approaches
                      </span>
                    </div>
                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <p className="mt-2">
                        We have identified certain sectors and approaches for
                        investors seeking to tap into India's consumption story.
                      </p>
                    </div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Consumer Discretionary Sector
                      </span>
                    </div>
                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <p className="mt-2">
                        Target companies that cater to aspirational spend in
                        segments such as electronics, automobiles, and luxury.
                        Identify brands with a strong digital presence and
                        successful omnichannel models.
                      </p>
                    </div>

                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        FMCG and Retail
                      </span>
                    </div>
                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <p className="mt-2">
                        Invest in firms that have robust distribution channels
                        in rural India. Evaluate companies that are innovating
                        in the health and well-being space.
                      </p>
                    </div>

                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Fintech and Digital Payments
                      </span>
                    </div>
                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <p className="mt-2">
                        Target firms that facilitate digital transactions and
                        promote financial inclusion. Search for innovative
                        players in the buy-now-pay-later (BNPL) space.
                      </p>
                    </div>

                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Education and Upskilling
                      </span>
                    </div>
                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <p className="mt-2">
                        Invest in ed-tech startups bridging India's humongous
                        skill gap. Consider players in the vocational education
                        and professional certification sector.
                      </p>
                    </div>

                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Healthcare and Pharma
                      </span>
                    </div>
                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <p className="mt-2">
                        Invest in companies scaling telemedicine and digital
                        health solutions. Look for pharma companies with robust
                        R&D pipelines for lifestyle diseases.
                      </p>
                    </div>

                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Navigating Challenges
                      </span>
                    </div>
                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                        Though the opportunities are humongous, investors must
                        be cognizant of possible challenges:
                      </li>

                      <li className="mt-2">
                        Regulatory Environment: Track evolving policies and
                        regulations.
                      </li>
                      <li className="mt-2">
                        Regional Diversity: Recognize that India is not one
                        market; strategies may have to be localized.
                      </li>
                      <li className="mt-2">
                        Infrastructure Gaps: See how companies are working
                        around logistics and infrastructure hurdles.
                      </li>
                    </div>
                  </div>

                  <img
                    src={newblogimg}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "350px",
                      height: "270px",
                    }}
                  />
                  <div className="mt-15">
                    <span
                      className="text-capitalize"
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "700",
                        color: "#002249",
                      }}
                    >
                      Conclusion
                    </span>
                  </div>
                  <p
                    className="text-dark servicep mt-4"
                    style={{ textAlign: "justify" }}
                  >
                    India's consumption boom is a once-in-a-generation
                    opportunity for investors looking for growth in emerging
                    markets, Investors can position themselves to capitalize on
                    the world's fastest-growing major economy. Whether you are
                    an experienced investor or new to the Indian market, BFAG
                    can be your go-to partner for all your Consulting, Financial
                    Accounting & Compiance Needs, incase you decide on tapping
                    the potential of India's economic renaissance.
                  </p>

                  <p
                    className="text-dark servicep mt-4"
                    style={{ textAlign: "justify" }}
                  >
                    Ready to capitalize on investment opportunities in India's
                    consumer market boom? Contact BFAG today for a customized
                    consultation and take the first step.
                  </p>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Decodingindiaconsumptionboom;
