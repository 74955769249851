import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import scale from "../../assets/images/blogs/scale.png";
import scale1 from "../../assets/images/blogs/scale1.png";
import scale2 from "../../assets/images/blogs/scale2.png";
import scale3 from "../../assets/images/blogs/scale3.png";

const Fromseedtoscale = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
       <style jsx>{`
  h4 {
    font-size: 18px;
  }
  h3 {
    font-size: 28px;
  }
  span {
    font-size: 16px;
  }
  p {
    font-size: 16px;
  }
  li {
    font-size: 16px;
    font-weight: 500;
  }
  .mainCard {
    height: 20rem;
  }
  .circle {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
  }
  i {
    color: #072f5f;
  }
  ul {
    padding-left: 17px;
  }
  @media (max-width: 574px) {
    .mTop {
      margin-top: 30px;
    }
    p {
      font-size: 18px;
    }
    .img-fluid {
      min-height: 100px;
    }
    .container-fluid {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @media (min-width: 992px) {
    /* for banner image */
    .bmTop {
      margin-top: 130px;
    }
  }
  @media (max-width: 574px) {
    .banner-image {
      margin-top: 70px; /* Adjust this value for mobile view */
    }
  }
`}</style>

      <section className="">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold", marginTop:'10px'  }}
            >
              From Seed to Scale:
              <br /> How Pune's CAs are Fuelling the City's Startup Ecosystem
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Introduction
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      ></span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      Pune, often called Maharashtra's startup hub, is
                      experiencing phenomenal growth in entrepreneurial
                      activity. At the heart of this vibrant ecosystem are the
                      city's Chartered Accountants (CAs), playing a pivotal role
                      in transforming innovative ideas into successful
                      businesses. This blog explores how Pune's CAs are
                      instrumental in nurturing startups from their inception to
                      growth stages.
                    </p>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        The Startup Ecosystem in Pune
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc",textAlign: "justify" }}
                    >
                      <li>
                        The startup scene in Pune is flourishing, especially in
                        IT, biotechnology, and manufacturing. The location is
                        strategic, and the pool of talent coupled with
                        supportive infrastructure makes it an ideal breeding
                        ground for new ventures. However, finance and compliance
                        become a little complicated for budding entrepreneurs,
                        and the CA professionals in Pune take over to lead
                        startups through multiple growth stages.
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Critical Role of CAs in Startup Success
                      </span>
                    </div>
                  </div>

                  <ul
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li className="mt-2">
                      1. Financial Planning and Management: The CAs of Pune are
                      highly efficient in guiding the startups to develop sound
                      financial models. Budgeting, cash flow management, and
                      financial forecasting are critical for sustainable growth.
                      The Pune CAs guide the startups in the process. The CAs
                      also provide insights into the financial health, which
                      helps startups make proper decisions and attract potential
                      investors.
                    </li>
                    <li className="mt-2">
                      2. Compliance and Regulatory Guidance: New ventures have
                      to navigate bewildering lines of tax laws and regulatory
                      requirements. Pune CAs ensure compliance to all local as
                      well as central rules for purposes like GST return,
                      company incorporation, and annual statutory audit.
                    </li>
                    <li className="mt-2">
                      3. Funding and Investment Advisory: The most important
                      thing for a startup is funding. The CAs in Pune prepare
                      the startups to go for the investment rounds. They support
                      the valuation and due diligence processes and ensure that
                      the financial presentation is highly appealing to
                      investors.
                    </li>
                    <li className="mt-2">
                      4.Financial risks must be identified and mitigated for
                      long-term survival. The risk assessment and management
                      expertise of CAs in Pune help the startup build a robust
                      business model.
                    </li>
                    <li className="mt-2">
                      5.The value of a CA goes beyond the numbers; they provide
                      input on market trends, help identify growth
                      opportunities, and advise on scaling strategies.
                    </li>
                  </ul>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Pune CAs: Taking on the Startup Ecosystem
                      </span>
                    </div>
                  </div>
                  <p
                    className="text-dark servicep"
                    style={{ textAlign: "justify" }}
                  >
                    The Pune CAs are becoming accustomed to the particular needs
                    of startups:{" "}
                  </p>
                  <ul
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li className="mt-4">
                      1. Specialization in Startup Finance: Several CAs in Pune
                      now specialize in startup accounting, thereby providing
                      customized services for new ventures.
                    </li>
                    <li className="mt-4">
                      2. Tech-Savvy Approach: Digital tools and cloud-based
                      accounting solutions that can provide real-time financial
                      insights.
                    </li>
                    <li className="mt-4">
                      3. Networking and Mentorship: CAs are increasingly being
                      sought as mentors, connecting startupswith potential
                      investors and partners.
                    </li>
                    <li className="mt-4">
                      4. Continuous Learning: In keeping abreast of the latest
                      in startup trends, technology, and financial innovations
                      to provide leading-edge advice.{" "}
                    </li>
                  </ul>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Success Stories: Pune Startups Powered by CA Expertise{" "}
                      </span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      The local CAs have guided many start-ups in Pune. Whether
                      it’s a tech startup that eventually raised significant
                      funds or manufacturing startups scaling up to become
                      national players, the CA difference stands out across all
                      sectors.
                    </p>
                    <p
                      className="text-dark servicep font-bold"
                      style={{ textAlign: "justify" }}
                    >
                      Road Ahead{" "}
                    </p>
                    <img
                    src={scale3}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "350px",
                      height: "270px",
                    }}
                  />
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                        As Pune emerges as a strong startup hub, the role of CAs
                        becomes even more important in shaping the ecosystem.
                        Their contribution not only supports individual startups
                        but also contributes to the overall economic growth of
                        the city.
                      </li>
                      <li className="mt-2">
                        A CA can be a game-changer for startups in Pune who want
                        to avail expert financial guidance. More than an
                        accounting service, these professionals provide a
                        roadmap for financial success and sustainable growth.
                      </li>
                      <li className="mt-2">
                        In a nutshell, Pune’s CAs are the unsung heroes of the
                        city's startup revolution. Their expertise, coupled with
                        a deep understanding of the local business landscape, is
                        fuelling the transformation of innovative ideas into
                        successful enterprises. As Pune's startup ecosystem
                        continues to flourish, the symbiotic relationship
                        between startups and CAs will undoubtedly play a crucial
                        role in shaping the city's economic future.
                      </li>
                    </ul>
                  </div>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Fromseedtoscale;
