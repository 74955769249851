import React from "react";
import Anoop from "../../../../assets/images/teams/AMehta.jpg";

const Anoopmehta = () => {
  return (
    <div className="min-vh-100 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 d-flex justify-content-center mb-3">
          <div
            style={{
              position: "sticky",
              top: "10px", // Equivalent to lg:top-4 (1rem in Tailwind)
              gridColumnStart: 1,
              gridRow: "span 2", // Equivalent to lg:row-span-2
              padding: "1%",
            }}
          >

            <img
              style={{ borderRadius: "10px", width: "100%" }}
              className="img-fluid"
              src={Anoop}
              alt="Anoop Mehta"
            />
          </div>

          </div>
          <div className="col-lg-8 d-flex flex-column justify-content-start">
            <h3
              className="text-uppercase text-dark title"
              style={{ fontSize: "20px", fontWeight: 700 }}
            >
              Anoop Natwar Mehta
            </h3>
            <div>
              <span
                className="text-uppercase text-dark"
                style={{ fontSize: "16px", fontWeight: 600 }}
              >
                ADVISOR
              </span>
              <a
                style={{
                  color: "#fff",
                  marginLeft: "10px",
                  backgroundColor: "#0072b1",
                  padding: "4px",
                  borderRadius: "5px",
                }}
                href="https://www.linkedin.com/in/anoop-n-mehta-cpa-cgma-2741ab1b/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in" style={{ color: "#fff" }}></i>
              </a>
            </div>
            <p
              className="mt-3 text-dark text-justify"
              style={{ fontSize: "16px" }}
            >
             <b> Mr. Anoop Natwar Mehta, CPA, CGMA</b>, is an esteemed leader in the
              accounting, taxation and finance profession, renowned for his
              extensive contributions and trailblazing achievements. As the Past
              Chairman of the American Institute of CPAS (AICPA) and the
              Immediate Past Chairman of the Association of International
              Certified Professional Accountants, Anoop made history as the
              first person of Indian origin and only the second minority to
              chair the AICPA in its 135-year history.{" "}
            </p>
            <p
              className="mt-3 text-dark text-justify"
              style={{ fontSize: "16px" }}
            >
              Currently, Anoop serves as the Chief Strategist for Analytical
              Mechanics Associates, Inc. (AMA), a firm known for tackling the
              most challenging engineering, science, and business problems for
              both government and commercial entities. In this role, he provides
              his expertise in business development, strategic planning, and
              operational improvements, driving significant advancements across
              AMA.{" "}
            </p>
            <p
              className="mt-3 text-dark text-justify"
              style={{ fontSize: "16px" }}
            >
              Anoop's distinguished career includes a notable tenure as the
              president of Science Systems and Applications, Inc. (SSAI), where
              he led the executive management team and oversaw the company's
              overall performance.{" "}
            </p>
            <div
  className="pl-0 pr-0 md:ml-[-52%] sm:ml-0 sm:px-4"
>
  <p className="mt-3 text-dark text-justify text-sm md:text-base">
    Under his leadership, SSAI grew from a two-person startup to a thriving
    organization with over 1,000 employees, consistently achieving profitability
    above target levels. His more than 40 years of experience in government
    contracting, particularly in supporting NASA and NOAA programs, underscores
    his exceptional leadership and strategic vision.
  </p>

  <p className="text-sm md:text-base">
    A dedicated volunteer, Anoop serves as the Chairman of the AICPA's National
    Commission on Diversity and Inclusion (NCDI). His commitment to the
    profession is further evidenced by his numerous roles within the AICPA and
  </p>
  <p className="mt-3 text-dark text-justify text-sm md:text-base">
    His involvement in CIMA, including positions on the AICPA Board of
    Directors, the Association Board of Directors, the AICPA Business and
    Industry Executive Committee, the AICPA Political Action Committee, and the
    AICPA Council.
  </p>

  <p className="mt-3 text-dark text-justify text-sm md:text-base">
    Anoop's leadership extends to the Maryland Association of Certified Public
    Accountants (MACPA), where he served as Chair (2012-2013) and on the
    Government Contractors Committee, which he chaired from 2001 to 2003. His
    academic background includes a Bachelor of Science degree in Accounting
    from the University of Maryland, College Park, and he continues to
    contribute to his alma mater as a member of the Board of Advisors for the
    Robert H. Smith School of Business and the Accounting and Information
    Assurance Department.
  </p>

  <p className="mt-3 text-dark text-justify text-sm md:text-base">
    In addition to his professional accomplishments, Anoop is recognized for
    his enterprising spirit. He is a seventh-degree Master Black Belt in Tae
    Kwon Do and has been teaching martial arts and operating a karate school
    for over 35 years, demonstrating his dedication, discipline, and leadership
    in all aspects of his life.
  </p>

  <p className="mt-3 text-dark text-justify text-sm md:text-base">
    Anoop N. Mehta's profound influence and unwavering commitment to excellence
    continue to inspire and drive the accounting profession forward, making him
    a highly respected and impactful leader.
  </p>
</div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Anoopmehta;
