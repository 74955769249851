import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import virtual from "../../assets/images/blogs/virtual.png";
import sustain from '../../assets/images/blogs/Sustainability.png'
import sustain1 from '../../assets/images/blogs/Sustainability1.png'
import sustain2 from '../../assets/images/blogs/Sustainability2.png'

const Sustainabilityreporting = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
<style jsx>{`
  h4 {
    font-size: 18px;
  }
  h3 {
    font-size: 28px;
  }
  span {
    font-size: 16px;
  }
  p {
    font-size: 16px;
  }
  li {
    font-size: 16px;
    font-weight: 500;
  }
  .mainCard {
    height: 20rem;
  }
  .circle {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
  }
  i {
    color: #072f5f;
  }
  ul {
    padding-left: 17px;
  }
  @media (max-width: 574px) {
    .mTop {
      margin-top: 30px;
    }
    p {
      font-size: 18px;
    }
    .img-fluid {
      min-height: 100px;
    }
  }
  @media (min-width: 992px) {
    /* for banner image */
    .bmTop {
      margin-top: 130px;
    }
  }
      @media (max-width: 574px) {
    .banner-image {
      margin-top: 70px; /* Adjust this value for mobile view */
    }
  }
`}</style>

      <section>
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold", marginTop:'10px' }}
            >
              Sustainability Reporting
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Introduction
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Sustainability Reporting: The Growing Importance for
                        CPAs, CAs, and CSs
                      </span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      In the rapidly evolving business landscape of 2025,
                      sustainability reporting has become a cornerstone of
                      corporate transparency and accountability. Certified
                      Public Accountants (CPAs), Chartered Accountants (CAs),
                      and Company Secretaries (CSs) are at the forefront of this
                      transformation, playing crucial roles in integrating
                      Environmental, Social, and Governance (ESG) factors into
                      financial reporting. This blog post explores the growing
                      importance of sustainability reporting and its impact on
                      accounting professionals.
                    </p>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      Overview of Sustainability Reporting Standards
                    </p>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        The sustainability reporting ecosystem has seen
                        significant consolidation and evolution in recent years.
                        Key frameworks and standards include:
                      </span>
                    </div>
                  </div>

                  <div
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li className="mt-2">
                     <b>Global Reporting Initiative (GRI):</b> GRI remains a
                      global benchmark for comprehensive sustainability
                      reporting, covering environmental, social, and economic
                      impacts.
                    </li>
                    <li className="mt-2">
                  
                      <b>
                        Sustainability Accounting Standards Board (SASB)
                        Standards:
                      </b>{" "}
                      Now part of the IFRS Foundation, SASB Standards focus on
                      financially material sustainability issues specific to
                      different industries.
                    </li>
                    <li className="mt-2">
                      
                      <b>
                        Task Force on Climate-related Financial Disclosures
                        (TCFD):
                      </b>{" "}
                      TCFD emphasizes climate-related risks and financial
                      impacts.
                    </li>
                    <li className="mt-2">
                 
                      <b>
                        International Sustainability Standards Board (ISSB):
                      </b>{" "}
                      Established in 2021, the ISSB aims to develop a
                      comprehensive global baseline for sustainability
                      disclosures, building on the work of SASB, TCFD, and other
                      organizations.
                    </li>
                  </div>
                  <p
                    className="text-dark servicep"
                    style={{ textAlign: "justify" }}
                  >
                    The Role of Accountants and Company Secretaries
                  </p>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Accounting professionals are pivotal in implementing and
                        auditing sustainability practices:
                      </span>
                    </div>

                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                     <b>Data Collection and Verification: </b> CPAs and
                        CAs are responsible for developing robust systems to
                        accurately collect and report climate-related and other
                        ESG data.
                      </li>
                      <li className="mt-2">
                   <b>Assurance Services: </b> With mandated attestation
                        for greenhouse gas emissions disclosures, accounting
                        professionals are expanding their assurance services to
                        cover these new areas.
                      </li>
                      <li className="mt-2">
                        <b>Risk Assessment: </b>
                        Identifying and quantifying climate-related and other
                        ESG risks while integrating these factors into financial
                        planning and reporting.
                      </li>
                      <li className="mt-2">
                       <b> Stakeholder Communication: </b>
                        Acting as a link between companies and investors by
                        effectively explaining complex ESG information.
                      </li>
                      <li className="mt-2">
                        <b> Policy Development: </b>
                        Company Secretaries play a crucial role in developing
                        and implementing ESG policies that align with regulatory
                        requirements and company values.
                      </li>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      Integration of ESG Factors into Financial Statements
                    </p>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        The integration of ESG factors into financial statements
                        is becoming increasingly sophisticated:
                      </span>
                    </div>

                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                <b>Holistic Reporting: </b> Accountants are working
                        to create a more comprehensive picture of company
                        performance by incorporating both financial and
                        non-financial (ESG) metrics into reports.
                      </li>
                      <li className="mt-2">
                    <b>Financial Materiality: </b>SASB Standards help
                        identify sustainability issues expected to have a
                        material impact on a company's financial performance.
                      </li>
                      <li className="mt-2">
                       <b> Investor-Focused Disclosures: </b>
                        The ISSB's work aims to meet investors' information
                        needs by providing a global baseline for sustainability
                        disclosures.
                      </li>
                      <p
                        className="text-dark servicep"
                        style={{ textAlign: "justify" }}
                      >
                        Challenges in Measuring and Reporting Non-Financial
                        Metrics{" "}
                      </p>
                    </div>

                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                       While progress has been made, several challenges remain:
                      </span>
                    </div>

                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                     <b>Standardization:</b> Despite efforts toward consolidation, multiple frameworks still exist, making comparability challenging.
                      </li>
                      <li className="mt-2">
                     <b>Data Quality: </b>Ensuring the accuracy and reliability of non-financial data remains a significant hurdle.
                      </li>
                      <li className="mt-2">
                       <b> 	Materiality Assessment :</b>
                        Determining which ESG factors are financially material for different industries and companies can be complex.
                      </li>
                      <li className="mt-2">
                      <b>Technology Integration: </b>
                        Implementing systems to efficiently collect, analyze, and report ESG data requires significant investment and expertise.                      </li>
                      <p
                        className="text-dark servicep"
                        style={{ textAlign: "justify" }}
                      >
                       Regulatory Landscape for Sustainability Reporting
                      </p>
                    </div>

                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                       The regulatory environment is rapidly evolving:
                      </span>
                    </div>

                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                        <b>USA:</b>The Securities and Exchange Commission (SEC) has proposed climate disclosure rules, though there have been recent developments affecting their implementation.
                      </li>
                      <li className="mt-2">
                      <b>India: </b>The Institute of Chartered Accountants of India (ICAI) is playing a pivotal role in promoting sustainability reporting, as highlighted at the World Forum of Accountants (WOFA) 2025.
                      </li>
                      <li className="mt-2">
                     <b>Global Alignment: </b>
                         There is a growing trend toward international alignment of sustainability reporting standards, as evidenced by the establishment of the ISSB.
                      </li>
                      <p
                        className="text-dark servicep"
                        style={{ textAlign: "justify" }}
                      >
                        Future Trends in Sustainability Accounting and Reporting
                      </p>
                    </div>

                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                       Looking ahead, several trends are shaping the future of sustainability reporting:
                      </span>
                    </div>

                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                      <b>Increased Integration: </b> Further integration of financial and non-financial reporting is expected, moving toward truly integrated reporting.
                      </li>
                      <li className="mt-2">
                        <b>Technology-Driven Solutions:  </b>Advanced analytics, AI, and blockchain are likely to play significant roles in improving the accuracy and efficiency of ESG reporting.
                      </li>
                      <li className="mt-2">
                       <b>Sector-Specific Standards: </b>
                        As exemplified by SASB, there is a move toward more industry-specific sustainability reporting standards.
                      </li>
                      <li className="mt-2">
                        <b>Global Standardization: </b>
                        The work of the ISSB suggests a future with more globally aligned sustainability reporting standards.                      </li>
                     
                    </div>
                  </div>

                  <img
                    src={sustain1}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "350px",
                      height: "270px",
                    }}
                  />

                  <p
                    className="text-dark servicep mt-4"
                    style={{ textAlign: "justify" }}
                  >
                   The importance of sustainability reporting for CPAs, CAs, and CSs cannot be overstated. As businesses navigate the complexities of ESG reporting, these professionals play a crucial role in ensuring transparency, accuracy, and compliance. The evolving landscape presents both challenges and opportunities, requiring continuous learning and adaptation to new standards and technologies. By embracing these changes, accounting professionals can drive sustainable business practices and create long-term value for organizations and society at large.
                  </p>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sustainabilityreporting;
