import React from 'react';
import engprocess from '../../../../assets/images/how we do it/EngProcess3.png';

const Engagementprocess = () => {
  return (
    <div className="container">
      <style>
        {`
          .nav-link,
          .nav-link.active,
          .nav-link:hover {
            text-decoration: none;
          }
            
        `}
      </style>
      <div className="row divmart d-flex flex-column justify-content-center align-items-center pl-3 pr-3">
        <div className="mb-5 col-lg-12 d-flex flex-column justify-content-center align-items-center mt-4">
          <h3
            className="text-center"
            style={{ borderBottom: '4px solid #072F5F', display: 'inline-block', fontWeight:'bold', color:'#072f5f' }}
          >
            ENGAGEMENT PROCESS
          </h3>
        </div>
        <div className="mb-5 d-flex justify-content-center">
          <img
            className="img-fluid"
            style={{ borderRadius: '15px', maxHeight: '30rem' }}
            src={engprocess}
            alt="engagement_process"
          />
        </div>
        <div className="col-12 pt-3 pb-3 pl-0 pr-0 ml-0 mr-0">
          <ul
            className="justify-content-between nav nav-pills mb-3"
            id="pills-tab"
            role="tablist"
            
          >
            <li className="nav-item col-lg-3 pl-0 pr-0" role="presentation">
              <button
                className="nav-link active border-0 text-white box1 mr-2"
                id="pills-home-tab"
                data-toggle="pill"
                data-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                style={{ background: '#072F5F', 
                  borderRadius: 20,
                   width: '100%', 
                   padding:'1.3rem'
                   }}
              >
                ASSESSMENT & PROPOSAL
              </button>
            </li>
            <li className="nav-item col-lg-3 pl-0 pr-0" role="presentation">
              <button
                className="nav-link border-0 text-white box2 mr-2"
                id="pills-profile-tab"
                data-toggle="pill"
                data-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                style={{ background: '#072F5F', borderRadius: 20, width: '100%', padding:'1.3rem'
                }}
              >
                WORK FLOW DESIGN
              </button>
            </li>
            <li className="nav-item col-lg-3 pl-0 pr-0" role="presentation">
              <button
                className="nav-link border-0 text-white mr-2"
                id="pills-contact-tab"
                data-toggle="pill"
                data-target="#pills-contact"
                type="button"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
                style={{ background: '#072F5F', borderRadius: 20, width: '100%' }}
              >
                SLA DEVELOPMENT AND RISK MANAGEMENT
              </button>
            </li>
            <li className="nav-item col-lg-3 pl-0 pr-0" role="presentation">
              <button
                className="nav-link border-0 text-white mr-2"
                id="pills-four-tab"
                data-toggle="pill"
                data-target="#pills-four"
                type="button"
                role="tab"
                aria-controls="pills-four"
                aria-selected="false"
                style={{ background: '#072F5F', borderRadius: 20, width: '100%' }}
              >
                IMPLEMENTATION AND SERVICE MANAGEMENT
              </button>
            </li>
          </ul>
          <div className="col-12 pl-0 ml-0 pr-0 mr-0 tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <p className="text-dark font-weight-bold">
                During this initial phase, Our Domain & Process experts work with the client to;
              </p>
              <ul className="text-dark" style={{ lineHeight: '2rem' }}>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Understand and document the Systems, Process that are in use
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Understand the corporate culture
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Assess the volume of transactions or process that is to be outsourced
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Understand the business the client is into and the specific requirements
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Define the desired process and the expected outcome
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Likely impact of transition
                </li>
              </ul>
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <p className="text-dark font-weight-bold">
                Once the proposal is accepted, we set forth to;
              </p>
              <ul className="text-dark" style={{ lineHeight: '2rem' }}>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Design the best work flow suitable for the outsourced activity
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Define the Process and internal controls, Scope
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Define the document flow
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Define the Organisation structure for the outsourced activity
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Define the desired process and the expected outcome
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Define the migration roadmap which includes timeline, resource deployment, software
                </li>
              </ul>
            </div>
            <div
              className="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              <p className="text-dark font-weight-bold">
                Based on the data gathered during the initial phases and on completion of process definition, scope, we decide on the below;
              </p>
              <ul className="text-dark" style={{ lineHeight: '2rem' }}>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> MIS structure and the frequency
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Timelines for reporting critical KPI’s
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Abnormal reports
                </li>
              </ul>
            </div>
            <div
              className="tab-pane fade"
              id="pills-four"
              role="tabpanel"
              aria-labelledby="pills-four-tab"
            >
              <p className="text-dark font-weight-bold">
                We follow different approaches to implementation of the agreed transition plan depending on the volume & complexity of business. This is a critical phase and as such we look into the following aspects;
              </p>
              <ul className="text-dark" style={{ lineHeight: '2rem' }}>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Each process is well defined and agreed, any changes to the same are updated
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Training of deployed staff on the standard operating process
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Information regarding SLA are shared
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Tracking status of implementation using various project management tools & perform course correction to make processes more efficient
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Processes are reviewed from viewpoint of consistent & accurate delivery of tasks assigned
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Ensure improved accuracy & efficiency by placing additional checks, automation wherever possible, internal control methods
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F' , marginTop:'0.5rem' }}></i> Overall outsourced activity is controlled thru regular reporting, monitoring and suitable dashboards
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Engagementprocess;
