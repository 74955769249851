import React from 'react';
import isotwo from '../../assets/images/service/isotwo.jpeg';

const Isopage = () => {
    const styles = {
        bannerImage: {
            background: `url(${isotwo}) no-repeat center`,
            backgroundSize: 'cover',
            height: '430px',
        },
        color: {
            color: '#343a40',
        },
        sectionTitle: {
            clear: 'both',
            textAlign: "left"
        },
        aboutMiddle: {
            listStyleType: 'disc',
            margin: 0,
            paddingLeft: '20px',
        },
        listItem: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            paddingLeft: '25px',
            marginBottom: '10px',
        },
        checkIcon: {
            marginRight: '10px',
        },
        dot: {
            content: '""',
            position: 'absolute',
            left: '10px',
            width: '6px',
            height: '6px',
            borderRadius: '50%',
            backgroundColor: '#343a40',
        },
    };

    return (
        <div className="col p-0 m-0">
            
            <div 
  className="d-flex justify-content-center align-items-center" 
  style={{
    background: `url(${isotwo}) no-repeat center`,
    backgroundSize: 'cover',
    height: '300px', // Reduced height for mobile
    width: '100%',
    maxWidth: '100vw',
    overflow: 'hidden',
    position: 'relative'
  }}
>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    {/*<h4 routerLink="/" className="cursor-pointer pt-2 shade" style={{ color: '#69a1bb' }}>Home | Services</h4>*/}
                </div>
            </div>

            <h3 className="text-center mt-4" style={{color:'#072f5f'}}>Information Security Management System</h3>
            <p className="text-center" style={styles.color}>Boston Financial Advisory Group is ISO 27001:2022 certified company. Where we maintain CIA Triad (Confidentiality, Integrity and Availability).</p>
            <p className="text-center" style={styles.color}>We maintain a clear framework of Information Security Management Process and key operational elements such as</p>

            <div className="choose-area-two choose-bg">
                <div className="container">
                    <div className="row my-list">
                        <div className="col-lg-12 col-md-12">
                            <div className="choose-content choose-content-title">
                                <div className="section-title" style={styles.sectionTitle}>
                                    {[
                                        'Keeping IT System up to date',
                                        'Information Security Policies',
                                        'Background Verification of Employees',
                                        'Anti-Virus Protection',
                                        'Data Storage & Backups',
                                        'IT Change Management',
                                        'Regular Information Security Training',
                                        'Regular Internal Audits',
                                        'Assets Management',
                                        'Information Classification',
                                        'Access Control & Access Rights',
                                        'Physical & Environmental Security',
                                        'Information Security Risk Assessment & Risk Treatment',
                                        'Protection of PII (Personally Identifiable Information)',
                                        'Network Security',
                                        'Regular VAPT (Vulnerability Assessment and Penetration Testing)',
                                        'Event Logging',
                                        'Dedicated CISO (Chief Information Security Officer)'
                                    ].map((item, index) => (
                                        <div key={index} className="col-md-4 about-middle" style={styles.aboutMiddle}>
                                            <li style={{ ...styles.color, ...styles.listItem }}>
                                                <i className="bx bx-check" style={styles.checkIcon}></i>{item}
                                                <span style={styles.dot}></span>
                                            </li>
                                        </div>
                                    ))}
                                    <div className="clearfix"></div>
                                    <p>&nbsp;</p>
                                    <p style={{ ...styles.color, fontWeight: 'bold' }}>
                                        <strong>ISO 27001 certificates may be requested via by writing to <a href="mailto:privacy@bostonfagroup.com">privacy@bostonfagroup.com</a>.</strong>
                                    </p>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>{`
                @media (max-width: 768px) {
                    .section-title {
                        text-align: center;
                    }
                }
            `}</style>
        </div>
    );
};

export default Isopage;
