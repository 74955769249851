import React from "react";
import "./ourteam.css";
import { Link } from "react-router-dom";
import prafulla from "../../../assets/images/teams/prafulla.png";
import sameer from "../../../assets/images/teams/sameer.png";
import sanjay from "../../../assets/images/teams/sanjay.png";
import ganesh from "../../../assets/images/teams/ganesh.png";
import ankushnew from "../../../assets/images/teams/ankushnew.png";
import amit from "../../../assets/images/teams/amit.png";
import kartik from "../../../assets/images/teams/teamMember.png";
import ammar from "../../../assets/images/teams/Ammar.jpg";
import leftvector from "../../../assets/images/teams/abstract-images/P-abstract-left.png";
import rightvector from "../../../assets/images/teams/abstract-images/P-abstract-right.png";
import leadershipVector from "../../../assets/images/teams/abstract-images/Leadership-abstract-left.png";
import vectorImagetwo from "../../../assets/images/teams/abstract-images/abstract-vector-left.png";
import vectorImagethree from "../../../assets/images/teams/abstract-images/abstract-vector-right.png";
import sunayana from "../../../assets/images/teams/sunayana.jpg";
import Pramod from "../../../assets/images/teams/Pramod.jpg";
import raguveer from "../../../assets/images/teams/raguveer.png";
import nagesh from "../../../assets/images/teams/nagesh.png";
import dummy from "../../../assets/images/teams/dummy-profile.jpg";
import gulam from "../../../assets/images/teams/Gulam_Hussain.png";
import vimal from "../../../assets/images/teams/vimal.png";
import vaman from "../../../assets/images/teams/vamanLD.jpeg";
import ujjal from "../../../assets/images/teams/Ujjal-Mukherjee.png";
import pabstractleft from "../../../assets/images/teams/abstract-images/P-abstract-left.png";
import abstractright from "../../../assets/images/teams/abstract-images/abstract-circle-right.png";
import abstractrleft from "../../../assets/images/teams/abstract-images/abstract-circle-left.png";
import anand from "../../../assets/images/teams/anandbang.jpg";
import Anoop from "../../../assets/images/teams/AMehta.jpg";
import ketan from "../../../assets/images/teams/KetanChoksi.jpg";
import videoBackground from "../../../assets/video/boston baneer video.mp4";
import niteshnew from "../../../assets/images/teams/Niteshnew.jpg";
import { Helmet } from "react-helmet";

const OurTeam = () => {
  return (
    <>
      <Helmet>
        <title>
          Boston Financial Advisors: Expert Corporate & CPA Financial Consulting
        </title>
        <meta
          name="description"
          content=" Financial Advisory Group offers comprehensive financial consulting services for businesses nationwide. Our team of CPAs and corporate financial advisors provides expert guidance in accounting, tax planning, and business strategy to help your company thrive in today's complex financial landscape. With a focus on American business needs, we deliver tailored solutions to drive success across the United States."
        />
        <meta
          name="keywords"
          content="Boston Financial Advisory Group, Corporate financial advisors Financial consultants,  Financial advisory firm, Financial consulting firm"
        />
      </Helmet>

      <div>
        <div
          className="text-center bottom pb-60"
          style={{ overflow: "hidden", position: "relative" }}
        >
          <div className="container">
            <h3
              className="title pt-50"
              style={{
                borderBottom: "3px solid #418052",
                display: "inline-block",
                width: "fit-content",
                fontWeight: "bold",
                color: "#072f5f",
              }}
            >
              Advisory Board
            </h3>
          </div>

          <div className="mt-80">
            <div
              className="row justify-content-center mt-50"
              style={{ gap: "2rem" }}
            >
              {/* anoop */}
              <Link
                to="/Anoopmehta"
                className="carrd cursor-pointer border-0 text-white shadeCSS"
                style={{
                  height: "280px",
                  width: "200px",
                  position: "relative",
                }}
              >
                <img
                  src={Anoop}
                  className="carrd-img img-fluid"
                  alt="Anoop Mehta"
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
                <div className="teamTital">
                  <h3
                    className="text-uppercase text-white m-0 p-2"
                    style={{ margin: 0, fontSize: "16px", fontWeight: "bold" }}
                  >
                    Anoop Natwar Mehta
                  </h3>
                  <div style={{ marginLeft: "22%" }}>
                    <span
                      className="text-white"
                      style={{
                        margin: 0,
                        transition: "all 0.3s",
                        fontSize: "16px",
                      }}
                    >
                      Advisor
                    </span>
                    <i
                      className="fab fa-linkedin-in"
                      style={{
                        marginLeft: "25px",
                        backgroundColor: "#0072b1",
                        padding: "4px",
                        borderRadius: "5px",
                        fontSize: "14px",
                        color: "#fff",
                      }}
                    ></i>
                  </div>
                </div>
              </Link>
              {/* anoop */}

              {/* prafulla */}
              <Link
                to="/prafullachajed"
                className="carrd cursor-pointer border-0 text-white shadeCSS"
                style={{
                  height: "280px",
                  width: "200px",
                  position: "relative",
                }}
              >
                <img
                  src={prafulla}
                  className="carrd-img img-fluid"
                  alt="Prafulla Chhajed"
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
                <div className="teamTital">
                  <h3
                    className="text-uppercase text-white m-0"
                    style={{
                      // margin: 0,
                      fontSize: "16px",
                      paddingLeft: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Prafulla Chhajed
                  </h3>
                  <div style={{ marginLeft: "22%" }}>
                    <span
                      className="text-white"
                      style={{
                        margin: 0,
                        transition: "all 0.3s",
                        fontSize: "16px",
                      }}
                    >
                      Advisor
                    </span>
                    <i
                      className="fab fa-linkedin-in"
                      style={{
                        marginLeft: "25px",
                        backgroundColor: "#0072b1",
                        padding: "4px",
                        color: "#fff",
                        borderRadius: "5px",
                        fontSize: "14px",
                      }}
                    ></i>
                  </div>
                </div>
              </Link>
              {/* prafulla */}

              {/* sanjay */}
              <Link
                to="/sanjaykapadia"
                className="carrd cursor-pointer border-0 text-white shadeCSS"
                style={{
                  height: "280px",
                  width: "200px",
                  position: "relative",
                }}
              >
                <img
                  src={sanjay}
                  className="carrd-img img-fluid"
                  alt="Sanjay Kapadia"
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
                <div className="teamTital" style={{ marginLeft: "9%" }}>
                  <h3
                    className="text-uppercase text-center text-white m-0"
                    style={{
                      margin: 0,
                      fontSize: "16px",
                      paddingLeft: "0.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    Sanjay Kapadia
                  </h3>
                  <div style={{ marginLeft: "15%" }}>
                    <span
                      className="text-white"
                      style={{
                        margin: 0,
                        transition: "all 0.3s",
                        fontSize: "16px",
                      }}
                    >
                      Advisor
                    </span>
                    <i
                      className="fab fa-linkedin-in"
                      style={{
                        marginLeft: "25px",
                        backgroundColor: "#0072b1",
                        padding: "4px",
                        color: "#fff",
                        borderRadius: "5px",
                        fontSize: "14px",
                      }}
                    ></i>
                  </div>
                </div>
              </Link>
              {/* sanjay */}

              {/* ketan */}
              <Link
                to="/ketanchokshi"
                className="carrd cursor-pointer border-0 text-white shadeCSS"
                style={{
                  height: "280px",
                  width: "200px",
                  position: "relative",
                }}
              >
                <img
                  src={ketan}
                  className="carrd-img img-fluid"
                  alt="ketan chokshi"
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
                <div className="teamTital" style={{ marginLeft: "3%" }}>
                  <h3
                    className="text-uppercase text-center text-white m-0"
                    style={{
                      margin: 0,
                      fontSize: "16px",
                      paddingLeft: "1.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    Ketan Chokshi
                  </h3>
                  <div style={{ marginLeft: "25%" }}>
                    <span
                      style={{
                        margin: 0,
                        color: "white",
                        transition: "all 0.3s",
                        fontSize: "16px",
                      }}
                    >
                      Advisor
                    </span>
                    <i
                      className="fab fa-linkedin-in"
                      style={{
                        marginLeft: "25px",
                        backgroundColor: "#0072b1",
                        padding: "4px",
                        color: "#fff",
                        borderRadius: "5px",
                        fontSize: "14px",
                      }}
                    ></i>
                  </div>
                </div>
              </Link>
              {/* ketan */}
            </div>

            <div className="container">
              <p className="mt-20 text-center text-dark pl-4 pr-4">
                Team BFAG is a group of passionate and committed professionals
                from diverse domains such as finance, accounting, law, IT, and
                compliance reporting with decades of experience across diverse
                sectors and geographies. Our CPA’s, CA’s, Corporate Secretaries,
                Counselors, Financial Analysts are experts in their domain with
                a focus on providing solutions. The team has worked on platforms
                such as SAP, Oracle, Xero, Sage, QB, and globally used
                jurisdiction-specific Tax software viz. CHH, Lacerte, etc.
              </p>
            </div>
          </div>
        </div>

        {/* LEADERSHIP Section */}
        <div
          className="text-center shadow-lg mt-50 pt-40 pb-60"
          style={{ overflow: "hidden", position: "relative" }}
        >
          <img
            src={leadershipVector}
            alt="shape"
            style={{
              position: "absolute",
              width: "14%",
              overflow: "clip",
              left: "0%",
              top: "0",
            }}
          />
          <div className="container">
            <h3
              className="title"
              style={{
                borderBottom: "3px solid #418052",
                display: "inline-block",
                fontWeight: "bold",
                color: "#072f5f",
              }}
            >
              LEADERSHIP
            </h3>
          </div>
          <div className="mt-50">
            <div
              className="row justify-content-center mt-50"
              style={{ gap: "2rem" }}
            >
              <Link
                to="/sameerpaddalwar"
                className="carrd cursor-pointer border-0 text-white shadeCSS"
                style={{
                  height: "280px",
                  width: "200px",
                  position: "relative",
                }}
              >
                <img
                  src={sameer}
                  className="carrd-img img-fluid"
                  alt="sameer paddalwar"
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
                <div className="teamTital">
                  <h3
                    className="text-uppercase text-white m-0"
                    style={{
                      margin: 0,
                      fontSize: "14px",
                      fontWeight: "bold",
                      paddingLeft: "1.5rem",
                    }}
                  >
                    SAMEER PADDALWAR
                  </h3>
                  <div style={{ marginLeft: "22%" }}>
                    <span
                      className="text-white"
                      style={{
                        margin: 0,
                        transition: "all 0.3s",
                        fontSize: "16px",
                      }}
                    >
                      CEO
                    </span>
                    <i
                      className="fab fa-linkedin-in"
                      style={{
                        marginLeft: "25px",
                        backgroundColor: "#0072b1",
                        padding: "4px",
                        color: "#fff",
                        borderRadius: "5px",
                        fontSize: "14px",
                      }}
                    ></i>
                  </div>
                </div>
              </Link>

              <Link
                to="/ganeshpadakannya"
                className="carrd cursor-pointer border-0 text-white shadeCSS"
                style={{
                  height: "280px",
                  width: "200px",
                  position: "relative",
                }}
              >
                <img
                  src={ganesh}
                  className="carrd-img img-fluid"
                  alt="Ganesh Padakannaya"
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
                <div className="teamTital">
                  <h3
                    className="text-uppercase text-white m-0"
                    style={{
                      margin: 0,
                      fontSize: "15px",
                      fontWeight: "bold",
                      paddingLeft: "0.5rem",
                    }}
                  >
                    GANESH PADAKANNAYA
                  </h3>
                  <div style={{ marginLeft: "22%" }}>
                    <span
                      className="text-white"
                      style={{
                        margin: 0,
                        transition: "all 0.3s",
                        fontSize: "16px",
                      }}
                    >
                      COO
                    </span>
                    <i
                      className="fab fa-linkedin-in"
                      style={{
                        marginLeft: "25px",
                        backgroundColor: "#0072b1",
                        padding: "4px",
                        color: "#fff",
                        borderRadius: "5px",
                        fontSize: "14px",
                      }}
                    ></i>
                  </div>
                </div>
              </Link>

              {/* vaman */}
              <Link
                to="/vaman-sriraman"
                className="carrd cursor-pointer border-0 text-white shadeCSS"
                style={{
                  height: "280px",
                  width: "200px",
                  position: "relative",
                }}
              >
                <img
                  src={vaman}
                  className="carrd-img img-fluid"
                  alt="vaman Sriraman"
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
                <div className="teamTital">
                  <h3
                    className="text-uppercase text-white m-0"
                    style={{
                      margin: 0,
                      fontSize: "15px",
                      fontWeight: "bold",
                      paddingLeft: "1.5rem",
                    }}
                  >
                    VAMAN Sriraman
                  </h3>
                  <div style={{ marginLeft: "22%" }}>
                    <span
                      className="text-white"
                      style={{
                        margin: 0,
                        transition: "all 0.3s",
                        fontSize: "16px",
                      }}
                    >
                      CTO
                    </span>
                    <i
                      className="fab fa-linkedin-in"
                      style={{
                        // marginRight: "-25px",
                        marginLeft: "20px",
                        backgroundColor: "#0072b1",
                        padding: "4px",
                        color: "#fff",
                        borderRadius: "5px",
                        fontSize: "14px",
                      }}
                    ></i>
                  </div>
                </div>
              </Link>

              {/* nitesh */}
              <Link
                to="/nitesh-vaswani"
                className="carrd cursor-pointer border-0 text-white shadeCSS"
                style={{
                  height: "280px",
                  width: "200px",
                  position: "relative",
                }}
              >
                <img
                  src={niteshnew}
                  className="carrd-img img-fluid"
                  alt="nitesh"
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
                <div className="teamTital">
                  <h3
                    className="text-uppercase text-white m-0"
                    style={{
                      margin: 0,
                      fontSize: "15px",
                      fontWeight: "bold",
                      paddingLeft: "2.2rem",
                    }}
                  >
                    Nitesh Vaswani
                  </h3>
                  <div style={{ paddingLeft: "1.5rem" }}>
                    <span
                      className="text-white"
                      style={{
                        margin: 0,
                        transition: "all 0.3s",
                        fontSize: "16px",
                      }}
                    >
                      VP
                    </span>
                    <i
                      className="fab fa-linkedin-in"
                      style={{
                        marginLeft: "25px",
                        backgroundColor: "#0072b1",
                        padding: "4px",
                        color: "#fff",
                        borderRadius: "5px",
                        fontSize: "14px",
                      }}
                    ></i>
                  </div>
                </div>
              </Link>

              <Link
                to="/amitmulgund"
                className="carrd cursor-pointer border-0 text-white shadeCSS"
                style={{
                  height: "280px",
                  width: "200px",
                  position: "relative",
                }}
              >
                <img
                  src={amit}
                  className="carrd-img img-fluid"
                  alt="Amit Mulgund"
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
                <div className="teamTital">
                  <h3
                    className="text-uppercase text-white m-0"
                    style={{
                      margin: 0,
                      fontSize: "15px",
                      paddingLeft: "2rem",
                      fontWeight: "bold",
                    }}
                  >
                    Amit Mulgund
                  </h3>
                  <div style={{ marginLeft: "12%" }}>
                    <span
                      className="text-white"
                      style={{
                        margin: 0,
                        transition: "all 0.3s",
                        fontSize: "16px",
                      }}
                    >
                      MD-EMEA
                    </span>
                    <i
                      className="fab fa-linkedin-in"
                      style={{
                        marginLeft: "15px",
                        backgroundColor: "#0072b1",
                        padding: "4px",
                        color: "#fff",
                        borderRadius: "5px",
                        fontSize: "14px",
                      }}
                    ></i>
                  </div>
                </div>
              </Link>

              <Link
                to="/kartikmehta"
                className="carrd cursor-pointer border-0 text-white shadeCSS"
                style={{
                  height: "280px",
                  width: "200px",
                  position: "relative",
                }}
              >
                <img
                  src={kartik}
                  className="carrd-img img-fluid"
                  alt="Kartik Mehta"
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
                <div
                  className="teamTital"
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    padding: "8px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h3
                    className="text-uppercase text-center text-white m-0"
                    style={{
                      margin: 0,
                      fontSize: "15px",
                      paddingLeft: "2rem",
                      fontWeight: "bold",
                    }}
                  >
                    Kartik Mehta
                  </h3>
                  <div className="d-flex" style={{ paddingLeft: "1.5rem" }}>
                    <span
                      className="text-white"
                      style={{
                        margin: 0,
                        transition: "all 0.3s",
                        fontSize: "16px",
                      }}
                    >
                      VP-TAX
                    </span>
                    <i
                      className="fab fa-linkedin-in"
                      style={{
                        marginLeft: "15px",
                        backgroundColor: "#0072b1",
                        padding: "4px",
                        color: "#fff",
                        borderRadius: "5px",
                        fontSize: "14px",
                      }}
                    ></i>
                  </div>
                </div>
              </Link>

              {/* ankush */}
              <Link
                to="/ankush-birewar"
                className="carrd cursor-pointer border-0 text-white shadeCSS"
                style={{
                  height: "280px",
                  width: "200px",
                  position: "relative",
                }}
              >
                <img
                  src={ankushnew}
                  className="carrd-img img-fluid"
                  alt="Ankush Birewar"
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
                <div className="teamTital">
                  <h3
                    className="text-uppercase text-white m-0"
                    style={{
                      margin: 0,
                      fontSize: "15px",
                      fontWeight: "bold",
                      paddingLeft: "1.5rem",
                    }}
                  >
                    Ankush Birewar
                  </h3>
                  <div style={{ marginLeft: "25px" }}>
                    <span
                      className="text-white"
                      style={{
                        margin: 0,
                        transition: "all 0.3s",
                        fontSize: "16px",
                      }}
                    >
                      VP - Operations
                    </span>
                    <i
                      className="fab fa-linkedin-in"
                      style={{
                        marginLeft: "5px",
                        backgroundColor: "#0072b1",
                        padding: "4px",
                        color: "#fff",
                        borderRadius: "5px",
                        fontSize: "14px",
                      }}
                    ></i>
                  </div>
                </div>
              </Link>

              <Link
                to="/ammartumpalliwar"
                className="carrd cursor-pointer border-0 text-white shadeCSS"
                style={{
                  height: "280px",
                  width: "200px",
                  position: "relative",
                }}
              >
                <img
                  src={ammar}
                  className="carrd-img img-fluid"
                  alt="Ammar Tumpalliwar"
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
                <div
                  className="teamTital"
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    padding: "8px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h3
                    className="text-uppercase text-center text-white m-0"
                    style={{
                      margin: 0,
                      fontSize: "15px",
                      fontWeight: "bold",
                      paddingLeft: "0.5rem",
                    }}
                  >
                    Ammar Tumpalliwar
                  </h3>
                  <div
                    className="d-flex"
                    style={{ marginLeft: "22%", paddingLeft: "1.5rem" }}
                  >
                    <span
                      className="text-white"
                      style={{
                        margin: 0,
                        transition: "all 0.3s",
                        fontSize: "16px",
                      }}
                    >
                      VP-BD
                    </span>
                    <i
                      className="fab fa-linkedin-in"
                      style={{
                        marginLeft: "25px",
                        backgroundColor: "#0072b1",
                        padding: "4px",
                        color: "#fff",
                        borderRadius: "5px",
                        fontSize: "14px",
                      }}
                    ></i>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        {/* Line Manger */}

        <div
          className="mt-70"
          style={{ overflow: "hidden", position: "relative" }}
        >
          <img
            src={vectorImagetwo}
            alt="shape"
            style={{
              position: "absolute",
              width: "22%",
              overflow: "clip",
              right: "-3%",
              top: "1%",
            }}
          />
          <img
            src={vectorImagethree}
            alt="shape"
            style={{
              position: "absolute",
              width: "22%",
              overflow: "clip",
              right: "-3%",
              top: "10%",
            }}
          />
          <img
            src={vectorImagetwo}
            alt="shape"
            style={{
              position: "absolute",
              width: "22%",
              overflow: "clip",
              left: "-3%",
              top: "22%",
            }}
          />
          <img
            src={vectorImagethree}
            alt="shape"
            style={{
              position: "absolute",
              width: "22%",
              overflow: "clip",
              right: "-3%",
              top: "30%",
            }}
          />
          <img
            src={pabstractleft}
            alt="shape"
            style={{
              position: "absolute",
              width: "22%",
              overflow: "clip",
              left: "-3%",
              top: "42%",
            }}
          />
          <img
            src={abstractright}
            alt="shape"
            style={{
              position: "absolute",
              width: "22%",
              overflow: "clip",
              right: "-3%",
              top: "50%",
            }}
          />
          <img
            src={abstractrleft}
            alt="shape"
            style={{
              position: "absolute",
              width: "22%",
              overflow: "clip",
              left: "-3%",
              top: "62%",
            }}
          />
          <img
            src={abstractright}
            alt="shape"
            style={{
              position: "absolute",
              width: "22%",
              overflow: "clip",
              right: "-3%",
              top: "70%",
            }}
          />
          <img
            src={abstractrleft}
            alt="shape"
            style={{
              position: "absolute",
              width: "22%",
              overflow: "clip",
              left: "-3%",
              top: "0%",
            }}
          />

          <div className="text-center pb-3">
            <h3
              style={{
                borderBottom: "3px solid #418052",
                display: "inline-block",
                fontWeight: "bold",
                color: "#072f5f",
              }}
            >
              LINE MANAGERS
            </h3>
          </div>
          {/* 1 */}

          <div class="container line-managers">
            <div class="row justify-content-center mt-5">
              <div class="col-lg-12 col-md-12">

{/* 1 */}
                {/* <div className="carrd shadow p-3 mb-5 bg-white rounded border-0">
                  <div className="row flex-column flex-md-row">
                    <div className="col-md-2 col-sm-12 d-flex justify-content-center align-items-center mb-3 mt-1">
                      <img
                        src={ankush}
                        className="img-fluid shadow"
                        style={{
                          borderRadius: "15px",
                          height: "150px",
                          width: "auto",
                        }}
                        alt="Ankush Birewar"
                      />
                    </div>
                    <div className="col-md-10 col-sm-12 d-flex flex-column justify-content-center text-center text-md-start">
                      <h5 className="text-uppercase mt-2 mb-1">
                        Ankush Birewar
                      </h5>
                      <small className="text-dark font-weight-bold">
                        VP - Operations
                      </small>
                      <p className="mt-2">
                        Ankush is a Chartered Accountant (CA) and Commerce
                        graduate with decades plus years of experience. He is
                        adept at handling clients with multi-Jurisdictional
                        presence and is well conversant with IFRS, US GAAP,
                        India GAAP, State and Federal Compliances. Valuation,
                        Due Diligence, Technical Accounting are his forte. His
                        strong analytical skills with the ability to interpret
                        complex information quickly and precisely is an asset.
                        As VP – Operations at Boston Financial Advisory Group,
                        he manages F&A Processes and is instrumental in
                        spearheading new segments.
                      </p>
                    </div>
                  </div>
                </div> */}
                {/* 2 */}

                {/* <div className="carrd shadow p-3 mb-5 bg-white rounded border-0">
                  <div className="row flex-column flex-md-row">
                    <div className="col-md-2 col-sm-12 d-flex justify-content-center align-items-center mb-3 mt-1">
                      <img
                        src={niteshnew}
                        className="img-fluid shadow"
                        style={{
                          borderRadius: "15px",
                          height: "150px",
                          width: "140px",
                        }}
                        alt="Nitesh Vaswani"
                      />
                    </div>
                    <div className="col-md-10 col-sm-12 d-flex flex-column justify-content-center text-center text-md-start">
                      <h5 className="text-uppercase mt-2 mb-1">
                        Nitesh Vaswani
                      </h5>
                      <small className="text-dark font-weight-bold">
                        VP – International Operations
                      </small>
                      <p className="mt-2 text-dark">
                        Nitesh, a Certified Public Accountant and Chartered
                        Accountant with an MBA in Finance, brings over a decade
                        of expertise in Mergers & Acquisitions, accounting
                        across jurisdictions, consolidation, reporting, and
                        compliance. His experience spans diverse industries,
                        including chemicals, construction, and healthcare,
                        giving him a robust understanding of international
                        reporting standards. As a tax compliance specialist,
                        Nitesh has consistently delivered value to international
                        clients, and his skill in managing multicultural teams
                        greatly enhances our company’s global capabilities.
                      </p>
                      <p className="mt-2 text-dark">
                        His experience spans diverse industries, including
                        chemicals, construction, and healthcare, giving him a
                        robust understanding of international reporting
                        standards. As a tax compliance specialist, Nitesh has
                        consistently delivered value to international clients,
                        and his skill in managing multicultural teams greatly
                        enhances our company’s global capabilities.
                      </p>
                    </div>
                  </div>
                </div> */}

                {/* 3 */}

                <div className="carrd shadow p-3 mb-5 bg-white rounded border-0">
                  <div className="row flex-column flex-md-row">
                    <div className="col-md-2 col-sm-12 d-flex justify-content-center align-items-center mb-3 mt-1">
                      <img
                        src={Pramod}
                        className="img-fluid shadow"
                        style={{
                          borderRadius: "15px",
                          height: "150px",
                          width: "auto",
                        }}
                        alt="Pramod KP"
                      />
                    </div>
                    <div className="col-md-10 col-sm-12 d-flex flex-column justify-content-center text-center text-md-start">
                      <h5 className="text-uppercase mt-2 mb-1">Pramod KP</h5>
                      <small className="text-dark font-weight-bold">
                        AVP – Regulatory
                      </small>
                      <p className="mt-2 text-dark">
                        Pramod K P is an astute professional with more than 15
                        years of experience across diverse industry segments.
                        Right from IT MNC’s, Construction companies, Project
                        Engineering companies, he has spearheaded the Tax and
                        Finance function with ease and aplomb. In-depth
                        knowledge on Direct & Indirect Taxes, Labour Laws &
                        other statutory compliances with focus on providing
                        solutions to complex situations are his forte.
                      </p>
                      <p className="mt-2 text-dark">
                        He is heading the Regulatory Function at BFAG for the
                        past 8 years and believes in developing leadership
                        capabilities in his people while cultivating an open and
                        transparent culture.
                      </p>
                    </div>
                  </div>
                </div>

                {/* 4 */}

                <div className="carrd shadow p-3 mb-5 bg-white rounded border-0">
                  <div className="row flex-column flex-md-row">
                    <div className="col-md-2 col-sm-12 d-flex justify-content-center align-items-center mb-3 mt-1">
                      <img
                        src={raguveer}
                        className="img-fluid shadow"
                        style={{
                          borderRadius: "15px",
                          height: "150px",
                          width: "auto",
                        }}
                        alt="Raghuveer S"
                      />
                    </div>
                    <div className="col-md-10 col-sm-12 d-flex flex-column justify-content-center text-center text-md-start">
                      <h5 className="text-uppercase mt-2 mb-1">Raghuveer S</h5>
                      <small className="text-dark font-weight-bold">
                        AVP – Taxation
                      </small>
                      <p className="mt-2 text-dark">
                        Raghuveer is a Chartered Accountant with 8+ years of
                        experience in US direct taxes. He has been leading the
                        CPA back-office operations wherein he manages
                        compilation, review, tax preparation, and filing for US
                        clients.
                      </p>
                    </div>
                  </div>
                </div>

                {/* 5 */}

                <div className="carrd shadow p-3 mb-5 bg-white rounded border-0">
                  <div className="row flex-column flex-md-row">
                    <div className="col-md-2 col-sm-12 d-flex justify-content-center align-items-center mb-3 mt-1">
                      <img
                        src={nagesh}
                        className="img-fluid shadow"
                        style={{
                          borderRadius: "15px",
                          height: "150px",
                          width: "auto",
                        }}
                        alt="Nagesh K C"
                      />
                    </div>
                    <div className="col-md-10 col-sm-12 d-flex flex-column justify-content-center text-center text-md-start">
                      <h5 className="text-uppercase mt-2 mb-1">Nagesh K C</h5>
                      <small className="text-dark font-weight-bold">
                        AVP – Operations
                      </small>
                      <p className="mt-2 text-dark">
                        Nagesh, a Chartered Accountant with 8+ years of
                        experience in Audit, Tax, and Accounting, manages
                        multi-jurisdictional accounting and tax compliances for
                        growth-stage companies. Very meticulous in his approach
                        and always delivers on time.
                      </p>
                    </div>
                  </div>
                </div>

                {/* 6 */}

                <div className="carrd shadow p-3 mb-5 bg-white rounded border-0">
                  <div className="row flex-column flex-md-row">
                    <div className="col-md-2 col-sm-12 d-flex justify-content-center align-items-center mb-3 mt-1">
                      <img
                        src={anand}
                        className="img-fluid shadow"
                        style={{
                          borderRadius: "15px",
                          height: "150px",
                          width: "150px",
                        }}
                        alt="ANAND BANG"
                      />
                    </div>
                    <div className="col-md-10 col-sm-12 d-flex flex-column justify-content-center text-center text-md-start">
                      <h5 className="text-uppercase mt-2 mb-1">ANAND BANG</h5>
                      <small className="text-dark font-weight-bold">
                        AVP - International Operations
                      </small>
                      <p className="mt-2 text-dark">
                        Anand Bang is an experienced international operations
                        manager with expertise in finance, compliance, and
                        strategic consultancy. With over a decade of experience,
                        he excels in accounting, financial reporting, statutory
                        compliance, and virtual CFO services. Anand is skilled
                        in audits, risk management, budgeting, forecasting, and
                        project consultancy. His proficiency in SAP environment,
                        revenue recognition, and variance analysis adds value to
                        organizational decision-making. Anand's dedication and
                        expertise make him an indispensable asset in
                        international operations management.
                      </p>
                    </div>
                  </div>
                </div>

                {/* 7 */}

                <div className="carrd shadow p-3 mb-5 bg-white rounded border-0">
                  <div className="row flex-column flex-md-row">
                    <div className="col-md-2 col-sm-12 d-flex justify-content-center align-items-center mb-3 mt-1">
                      <img
                        src={gulam}
                        className="img-fluid shadow"
                        style={{
                          borderRadius: "15px",
                          height: "150px",
                          width: "auto",
                        }}
                        alt="Gulam Hussain"
                      />
                    </div>
                    <div className="col-md-10 col-sm-12 d-flex flex-column justify-content-center text-center text-md-start">
                      <h5 className="text-uppercase mt-2 mb-1">
                        Gulam Hussain
                      </h5>
                      <small className="text-dark font-weight-bold">
                        AVP - MEA
                      </small>
                      <p className="mt-2 text-dark">
                        Gulam is a dedicated professional and qualified
                        Chartered Accountant (ICAI) with over 15 years of
                        extensive experience, including 9 years in the UAE. His
                        specializes in Financial Consultancy, VAT Consultancy,
                        Taxation, Bookkeeping, Audit, and Management
                        Consultancy. With exceptional analytical abilities and
                        attention to detail, he provides accurate and reliable
                        financial solutions. In 2018, he established his own
                        Financial Consultancy, serving clients with Financial
                        Consultancy, Tax Consultancy, and CFO Services. Gulam
                        has a strong background in accounting, taxation, and
                        business regulations enabled him to offer comprehensive
                        support to his 100+ satisfied clients. He has joined
                        BFAG MEA with an aim to foster growth through a
                        dedicated team of professionals. Gulam is also a
                        qualified D.I.S.A. (ICAI) and holds an M.Com degree.
                      </p>
                    </div>
                  </div>
                </div>

                {/* 8 */}

                <div className="carrd shadow p-3 mb-5 bg-white rounded border-0">
                  <div className="row flex-column flex-md-row">
                    <div className="col-md-2 col-sm-12 d-flex justify-content-center align-items-center mb-3 mt-1">
                      <img
                        src={vimal}
                        className="img-fluid shadow"
                        style={{
                          borderRadius: "15px",
                          height: "150px",
                          width: "auto",
                        }}
                        alt="Vimal Chande"
                      />
                    </div>
                    <div className="col-md-10 col-sm-12 d-flex flex-column justify-content-center text-center text-md-start">
                      <h5 className="text-uppercase mt-2 mb-1">Vimal Chande</h5>
                      <small className="text-dark font-weight-bold">
                        Controller – Operations
                      </small>
                      <p className="mt-2 text-dark">
                        Bringing with him a 15 plus years of experience in
                        Corporate Financial reporting, Financial Analytics,
                        Audits etc. Having worked in sectors such as Logistics,
                        Marine and Other multiple sectors he is well conversant
                        with the complex requirements of each sector. Developing
                        MIS Systems for an organization is his forte and excels
                        his role in Financial Management, Due diligence etc. His
                        focus always has been on continuous improvement in
                        process as he believes “Change is always permanent “
                        hence drives with scope for improvement in work process
                        and bringing effective results for the organization.
                      </p>
                    </div>
                  </div>
                </div>

                {/* 9 */}

                <div className="carrd shadow p-3 mb-5 bg-white rounded border-0">
                  <div className="row flex-column flex-md-row">
                    <div className="col-md-2 col-sm-12 d-flex justify-content-center align-items-center mb-3 mt-1">
                      <img
                        src={sunayana}
                        className="img-fluid shadow"
                        style={{
                          borderRadius: "15px",
                          height: "150px",
                          width: "auto",
                        }}
                        alt="sunayana"
                      />
                    </div>
                    <div className="col-md-10 col-sm-12 d-flex flex-column justify-content-center text-center text-md-start">
                      <h5 className="text-uppercase mt-2 mb-1">Sunayana J</h5>
                      <small className="text-dark font-weight-bold">
                        Manager - Corporate Compliance
                      </small>
                      <p
                        className="mt-2 text-dark"
                        style={{ lineHeight: "normal" }}
                      >
                        CS Sunayana J is a Graduate in Commerce, an Associate
                        member of Institute of Company Secretaries of India and
                        a Graduate in Law from Karnataka State Law University.
                        She comes with more than 7 years of extensive expertise
                        in the area of Corporate Laws including business set up
                        in India and Overseas and FEMA. She has also served as a
                        Financial Analyst in Tata Consultancy Services (TCS) for
                        a period of 2 years.
                      </p>
                    </div>
                  </div>
                </div>

                {/* 10 */}

                {/* <div className="carrd shadow p-3 mb-5 bg-white rounded border-0">
                  <div className="row flex-column flex-md-row">
                    <div className="col-md-2 col-sm-12 d-flex justify-content-center align-items-center mb-3 mt-1">
                      <img
                        src={anirudh}
                        className="img-fluid shadow"
                        style={{
                          borderRadius: "15px",
                          height: "150px",
                          width: "auto",
                        }}
                        alt="Anirudh Pal"
                      />
                    </div>
                    <div className="col-md-10 col-sm-12 d-flex flex-column justify-content-center text-center text-md-start">
                      <h5 className="text-uppercase mt-2 mb-1">Anirudh Pal</h5>
                      <small className="text-dark font-weight-bold">
                        AVP – IT & Systems
                      </small>
                      <p className="mt-2 text-dark">
                        Anirudh comes with 15 plus years of experience with
                        CISCO certification. He has virtuous knowledge in
                        accomplishment of IT Infrastructure and Networking &
                        Infrastructure Project management aligning to the
                        organizational strategy. He manages the end-to-end IT
                        Infrastructure setup at internal Datacenter at BFAS. He
                        excels in his role with effective Budget Planning for IT
                        Operations (Budget vs Actuals) and Asset Inventory
                        Management.
                      </p>
                    </div>
                  </div>
                </div> */}

                {/* 11 */}

                {/* <div className="carrd shadow p-3 mb-5 bg-white rounded border-0">
                  <div className="row flex-column flex-md-row">
                    <div className="col-md-2 col-sm-12 d-flex justify-content-center align-items-center mb-3 mt-1">
                      <img
                        src={vedang}
                        className="img-fluid shadow"
                        style={{
                          borderRadius: "15px",
                          height: "150px",
                          width: "auto",
                        }}
                        alt="Vedang Shevade"
                      />
                    </div>
                    <div className="col-md-10 col-sm-12 d-flex flex-column justify-content-center text-center text-md-start">
                      <h5 className="text-uppercase mt-2 mb-1">
                        Vedang Shevade
                      </h5>
                      <small className="text-dark font-weight-bold">
                        AVP – Corporate Compliance
                      </small>
                      <p className="mt-2 text-dark">
                        Vedang is a Company Secretary from the Institute of
                        Company Secretaries of India. He is also a graduate in
                        Law and Commerce. Heading the Corporate Secretarial
                        division of BFAG, Vedang comes with more than 7 years of
                        extensive expertise in the field of Corporate Laws,
                        SEBI, Foreign Exchange Management Compliances, Legal Due
                        Diligence, drafting, reviewing and vetting of various
                        legal agreements, shareholder’s transaction documents.
                        He is an expert in business set up whether in India or
                        Overseas.
                      </p>
                    </div>
                  </div>
                </div> */}

                {/* 12 */}

                <div className="carrd shadow p-3 mb-5 bg-white rounded border-0">
                  <div className="row flex-column flex-md-row">
                    <div className="col-md-2 col-sm-12 d-flex justify-content-center align-items-center mb-3 mt-1">
                      <img
                        src={ujjal}
                        className="img-fluid shadow"
                        style={{
                          borderRadius: "15px",
                          height: "150px",
                          width: "auto",
                        }}
                        alt="Ujjal Mukherjee"
                      />
                    </div>
                    <div className="col-md-10 col-sm-12 d-flex flex-column justify-content-center text-center text-md-start">
                      <h5 className="text-uppercase mt-2 mb-1">
                        Ujjal Mukherjee
                      </h5>
                      <small className="text-dark font-weight-bold">
                        Product Manager
                      </small>
                      <p className="mt-2 text-dark">
                        Ujjal is a skilled Product Manager with 7+ years of
                        experience in end-to-end product and project management.
                        He excels in project scoping, execution, and delivery,
                        working closely with stakeholders at all levels,
                        including senior executives and cross-functional teams.
                        Proficient in project management, agile methodologies,
                        and data-driven insights, he holds a B. Tech and PGCPM
                        from IIM Indore. Leading the Design & Development of
                        COMPLIANCE iNBOX, he showcases strong leadership and an
                        unwavering commitment to excellence, consistently
                        achieving outstanding results in dynamic business
                        environments.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurTeam;
