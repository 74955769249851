import React from 'react';
import sameer from "../../../../assets/images/teams/sameer cercile.jpeg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Sameerpaddalwar = () => {
  return (
    <div className="min-vh-100 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 d-flex justify-content-center mb-3">
            <div style={{ padding: '1%' }}>
              <img
                style={{ borderRadius: '10px', width: '100%', height:'420px' }}
                className="img-fluid"
                src={sameer}
                alt="sameer"
              />
            </div>
          </div>
          <div className="col-lg-8 d-flex flex-column justify-content-start">
            <h3 className="text-uppercase text-dark title" style={{ fontSize: '20px', fontWeight: 700 }}>
              SAMEER PADDALWAR
            </h3>
            <div>
              <span className="text-uppercase text-dark" style={{ fontSize: '16px', fontWeight: 600 }}>
                ADVISOR
              </span>
              <a
                style={{ color: '#fff', marginLeft: '10px', backgroundColor: '#0072b1', padding: '4px', borderRadius: '5px' }}
                href="https://www.linkedin.com/in/sameerpaddalwar/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedinIn} style={{ color: '#fff' }} />
              </a>
            </div>
            <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
              Sameer is a highly accomplished leader renowned for his exceptional track record in driving organizational success and fostering growth. With a steadfast focus on leadership capabilities and a wealth of experience in team building, he has played an instrumental role as the driving force and promoter behind the thriving Boston Financial Advisory group.
            </p>
            <p className=" text-dark text-justify" style={{ fontSize: '16px' }}>
              Sameer's academic achievements and extensive industry expertise have positioned him as a trusted and relied-upon figure within the firm. Holding certifications as a Certified Public Accountant (CPA), Certified Management Accountant (CMA), and Certified Financial Manager (CFM), he exemplifies his profound proficiency in financial management and compliance. With an impressive three decades of industry experience, Sameer's strategic vision and unwavering commitment to excellence have propelled the group to unprecedented levels of revenue growth and performance improvement for over a decade.
            </p>
            <p className=" text-dark text-justify" style={{ fontSize: '16px' }}>
              His profound understanding of compliance and regulatory requirements led to the creation of the ground-breaking solution known as Compliance in Box. Under Sameer's guidance, Compliance in Box and the CFO dashboards have had a transformative impact on operations, elevating the role of public accounting and financial management within organizations while streamlining processes.
            </p>
           
           
            <div class=" pl-0 pr-0">           
            <p className="text-dark text-justify" style={{ fontSize: '16px' }}>
              His expertise lies in global corporate structuring, strategic partner and investor relationship management, M&A synergies, post-acquisition business integration management, financial re-engineering, systems transformation, and reducing product portfolio complexity.
            </p>
            <p className=" text-dark text-justify" style={{ fontSize: '16px' }}>
            His dedication to excellence, coupled with a relentless pursuit of technological advancements, has
            enabled him to deliver innovative products that revolutionize the industry.
            </p>
            <p className="mt-3 text-dark text-justify pb-5" style={{ fontSize: '16px' }}>
              As a visionary and dynamic leader with extensive experience in leadership, team building, and technology advancement, Sameer has a wealth of expertise in all these areas. He consistently inspires and motivates his teams to reach their full potential and fosters a collaborative and inclusive work environment, encouraging innovation and embracing technological advancements to drive business success. His exceptional ability to build and nurture high-performing teams has been instrumental in the group's continued growth and triumph. Sameer’s profound knowledge of compliance and financial management, combined with his strategic acumen, has left an indelible mark on the Boston Financial Advisory Group and the industry.
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sameerpaddalwar;
