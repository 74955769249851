import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import virtual from "../../assets/images/blogs/virtual.png";
import envole from '../../assets/images/blogs/envole.png'
import envole1 from '../../assets/images/blogs/envole1.png'

const Evolvingroleaccountingprofessional = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
     <style jsx>{`
  h4 {
    font-size: 18px;
  }
  h3 {
    font-size: 28px;
  }
  span {
    font-size: 16px;
  }
  p {
    font-size: 16px;
  }
  li {
    font-size: 16px;
    font-weight: 500;
  }
  .mainCard {
    height: 20rem;
  }
  .circle {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
  }
  i {
    color: #072f5f;
  }
  ul {
    padding-left: 17px;
  }
  @media (max-width: 574px) {
    .mTop {
      margin-top: 30px;
    }
    p {
      font-size: 18px;
    }
    .img-fluid {
      min-height: 100px;
    }
    .container-fluid {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @media (min-width: 992px) {
    /* for banner image */
    .bmTop {
      margin-top: 130px;
    }
  }
  @media (max-width: 574px) {
    .banner-image {
      margin-top: 70px; /* Adjust this value for mobile view */
    }
  }
`}</style>

      <section>
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold", marginTop:'10px' }}
            >
              The Evolving Role of Accounting Professionals
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Introduction
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        The Evolving Role of Accounting Professionals in ESG
                        Practices: A 2025 Perspective
                      </span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      In the rapidly changing landscape of corporate
                      responsibility and sustainability, accounting
                      professionals are at the forefront of a new era.
                      Environmental, Social, and Governance (ESG) practices have
                      become integral to business operations, making the
                      expertise of Certified Public Accountants (CPAs),
                      Chartered Accountants (CAs), and Company Secretaries (CSs)
                      more crucial than ever.
                    </p>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      This blog explores how these professionals are adapting to
                      and shaping the world of ESG reporting and compliance in
                      2025.
                    </p>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        CPAs in the USA: Navigating New SEC Climate Disclosure
                        Rules
                      </span>
                    </div>
                  </div>
                  <p
                    className="text-dark servicep"
                    style={{ textAlign: "justify" }}
                  >
                    The Securities and Exchange Commission’s (SEC) climate
                    disclosure rules, initially adopted in 2024, have
                    significantly impacted the role of CPAs in the United
                    States. These professionals are now tasked with:
                  </p>

                  <div
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li className="mt-2">
                      <b> Implementing Robust Reporting Systems:</b> CPAs are
                      developing and overseeing systems to accurately collect
                      and report climate-related data, ensuring compliance with
                      SEC requirements.
                    </li>
                    <li className="mt-2">
                      <b> Providing Assurance Services:</b> With the SEC mandating
                      attestation for greenhouse gas (GHG) emissions
                      disclosures, CPAs are expanding their assurance services
                      to cover these new areas.
                    </li>
                    <li className="mt-2">
                     <b> Conducting Risk Assessments:</b> CPAs are helping companies
                      identify and quantify climate-related risks, integrating
                      these factors into financial planning and reporting.
                    </li>
                    <li className="mt-2">
                     <b> Enhancing Stakeholder Communication:</b> As the bridge
                      between companies and investors, CPAs play a crucial role
                      in effectively communicating complex climate-related
                      financial information.{" "}
                    </li>
                  </div>
                  <p
                    className="text-dark servicep"
                    style={{ textAlign: "justify" }}
                  >
                    However, recent developments have added complexity to this
                    evolving landscape. The SEC’s decision to pause the legal
                    defense of its climate rule in February 2025 has created
                    uncertainty in the field. CPAs must now navigate this
                    shifting regulatory environment while continuing to meet the
                    growing demand for climate-related financial information
                    from investors and other stakeholders.
                  </p>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Chartered Accountants in India: Integrating
                        Sustainability Metrics
                      </span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      In India, Chartered Accountants are witnessing a surge in
                      demand for their expertise in integrating sustainability
                      metrics into financial statements. This shift is driven
                      by:{" "}
                    </p>
                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                       <b>Global Alignment: </b> Indian companies are
                        aligning with international sustainability reporting
                        standards to remain competitive in the global market.
                      </li>
                      <li className="mt-2">
               <b>Investor Pressure:</b> Investors are demanding
                        more comprehensive ESG data, pushing CAs to develop new
                        methodologies for capturing and reporting this
                        information.
                      </li>
                      <li className="mt-2">
                <b>Regulatory Changes:</b>
                        Anticipated new sustainability disclosure requirements
                        from Indian regulators are prompting proactive measures
                        in the accounting profession.
                      </li>
                      <li className="mt-2">
                        <b>Holistic Reporting:</b>
                        CAs are working to create a more complete picture of
                        company performance by incorporating both financial and
                        non-financial (ESG) metrics into reports.
                      </li>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      The Institute of Chartered Accountants of India (ICAI) is
                      playing a pivotal role in this transition. The World Forum
                      of Accountants (WOFA) 2025, hosted by ICAI, highlighted
                      the importance of integrating ESG metrics into financial
                      statements, signaling a strong push towards sustainability
                      in the Indian accounting sector.
                    </p>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Company Secretaries: Ensuring ESG-Aligned Corporate
                        Governance
                      </span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      Company Secretaries have seen their responsibilities
                      expand significantly in the ESG era. Their key roles now
                      include:{" "}
                    </p>
                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                        <b>Developing ESG Policies: </b> CSs are instrumental
                        in formulating and implementing ESG policies that align
                        with regulatory requirements and company values.
                      </li>
                      <li className="mt-2">
                        <b>Advising Boards:</b> They play a crucial role in
                        advising boards on ESG-related risks and opportunities,
                        ensuring governance structures support sustainability
                        goals.
                      </li>
                      <li className="mt-2">
                       <b>Engaging Stakeholders:</b>
                        CSs are increasingly involved in managing relationships
                        with investors, regulators, and the community on ESG
                        matters.
                      </li>
                      <li className="mt-2">
                       <b> Monitoring Compliance: </b>
                        With the growing complexity of ESG regulations, CSs are
                        at the forefront of ensuring ongoing compliance and
                        reporting.
                      </li>
                      <li className="mt-2">
                       <b>Promoting Ethical Leadership: </b>
                        CSs champion ethical business practices, which are
                        fundamental to strong ESG performance.{" "}
                      </li>
                    </div>
                  </div>

                  <img
                    src={envole}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "350px",
                      height: "270px",
                    }}
                  />

                  <p
                    className="text-dark servicep mt-4"
                    style={{ textAlign: "justify" }}
                  >
                    By 2025, the role of accounting professionals in ESG
                    practices has evolved dramatically. CPAs, CAs, and CSs are
                    no longer just number crunchers—they are strategic advisors,
                    compliance experts, and key players in driving sustainable
                    business practices. As ESG continues to reshape the
                    corporate landscape, these professionals remain crucial in
                    building trust, ensuring transparency, and driving long-term
                    value creation in the business world.
                  </p>

                  <p
                    className="text-dark servicep mt-4"
                    style={{ textAlign: "justify" }}
                  >
                    The challenge now lies in continuously adapting to evolving
                    regulations, developing new skills, and balancing
                    traditional financial reporting with the demands of a
                    sustainability-focused future. Moving forward, the
                    accounting profession will undoubtedly continue to play a
                    pivotal role in shaping a more sustainable and responsible
                    business environment.
                  </p>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Evolvingroleaccountingprofessional;
