import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import virtual from "../../assets/images/blogs/virtual.png";
import taxing from '../../assets/images/blogs/taxing.png'
import taxing2 from '../../assets/images/blogs/taxing2.png'

const Navigatingusindiacrossborder = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
       <style jsx>{`
  h4 {
    font-size: 18px;
  }
  h3 {
    font-size: 28px;
  }
  span {
    font-size: 16px;
  }
  p {
    font-size: 16px;
  }
  li {
    font-size: 16px;
    font-weight: 500;
  }
  .mainCard {
    height: 20rem;
  }
  .circle {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
  }
  i {
    color: #072f5f;
  }
  ul {
    padding-left: 17px;
  }
  @media (max-width: 574px) {
    .mTop {
      margin-top: 30px;
    }
    p {
      font-size: 18px;
    }
    .img-fluid {
      min-height: 100px;
    }
    .container-fluid {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @media (min-width: 992px) {
    /* for banner image */
    .bmTop {
      margin-top: 130px;
    }
  }
  @media (max-width: 574px) {
    .banner-image {
      margin-top: 70px; /* Adjust this value for mobile view */
    }
  }
`}</style>

      <section>
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold", marginTop:'10px' }}
            >
              Navigating US-India Cross-Border Taxation
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Introduction
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Navigating US-India Cross-Border Taxation: Key Updates
                        for 2025
                      </span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      In an increasingly interconnected global economy,
                      understanding cross-border taxation is crucial for
                      businesses operating between the United States and India.
                      As we move into 2025, several significant updates and
                      reforms have been introduced that will shape the landscape
                      of US-India taxation. This blog post explores these
                      changes and their implications for businesses and
                      individuals engaged in cross-border activities.
                    </p>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        1. India’s Budget 2025: A Game-Changer for International
                        Financial Services
                      </span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      India’s recent Budget 2025 has introduced a series of tax
                      and regulatory reforms aimed at boosting the country’s
                      International Financial Services Centers (IFSC) and
                      attracting global financial operations. Key highlights
                      include:{" "}
                    </p>
                  </div>

                  <div
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li className="mt-2">
                      <b>Extended Tax Concessions: </b> Tax benefits for IFSC
                      units have been extended until March 31, 2030, covering
                      capital gains exemptions and other specific benefits under
                      Sections 10 and 80LA.
                    </li>
                    <li className="mt-2">
                      <b>Life Insurance Policy Exemptions:</b> IFSC Insurance
                      Offices can now offer life insurance policies with exempt
                      proceeds, removing the current cap on premiums.
                    </li>
                    <li className="mt-2">
                      <b>Ship Leasing Tax Exemptions:</b> Capital gains and
                      dividend exemptions have been introduced for ship leasing
                      units within the IFSC, similar to existing aircraft
                      leasing benefits.
                    </li>
                    <li className="mt-2">
                      <b>Revised ‘Dividend’ Definition</b>
                      Loans or advances between group entities, where one is a
                      finance unit in the IFSC, are now excluded from the
                      definition of “dividend.”
                    </li>
                  </div>
                  <p
                    className="text-dark servicep"
                    style={{ textAlign: "justify" }}
                  >
                    These changes present new opportunities for US companies
                    looking to expand their operations in India, particularly in
                    the financial services sector.{" "}
                  </p>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        2. Simplified Regime for IFSC-Based Fund Managers
                      </span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      A significant development for asset management companies
                      is the introduction of a simplified tax regime for fund
                      managers operating in the IFSC. This includes:
                    </p>
                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                        <b>Relaxed Conditions: </b> Looser restrictions for
                        Indian residents investing in funds managed by IFSC fund
                        managers.
                      </li>
                      <li className="mt-2">
                        <b>Enhanced Competitiveness:</b> Adjustments to make the
                        IFSC regime more attractive compared to foreign
                        jurisdictions.
                      </li>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      US-based fund managers considering expansion into India
                      should closely examine these new provisions, as they could
                      offer substantial tax benefits and operational advantages.{" "}
                    </p>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        3. Clarification on Significant Economic Presence (SEP)
                      </span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      The Budget 2025 has introduced an important clarification
                      regarding Significant Economic Presence (SEP) in India:
                    </p>
                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                        Transactions limited to purchasing goods for export will
                        not create a significant economic presence in India.
                      </li>
                      <li className="mt-2">
                        <b>This clarification aims to:</b>
                        <ul>Harmonize tax provisions</ul>
                        <ul>Reduce conflicts</ul>
                        <ul>Enhance the ease of doing business in India</ul>
                      </li>

                      <p
                        className="text-dark servicep"
                        style={{ textAlign: "justify" }}
                      >
                        US exporters engaged in transactions with Indian
                        entities should take note of this update, as it may
                        impact their tax liability in India.
                      </p>
                    </div>

                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        4. The New Income Tax Bill 2025
                      </span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      In a landmark move, India has proposed a new Income Tax
                      Bill to replace the existing Income-tax Act, 1961. While
                      retaining existing taxation principles and rates, the bill
                      focuses on:{" "}
                    </p>
                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                        Simplifying language and improving structure
                      </li>
                      <li className="mt-2">Reducing complexity</li>
                      <li className="mt-2">
                        Consolidating chapters and sections
                      </li>

                      <p
                        className="text-dark servicep"
                        style={{ textAlign: "justify" }}
                      >
                        US businesses and individuals dealing with Indian tax
                        matters should prepare for these changes, as they aim to
                        make compliance easier and more streamlined.{" "}
                      </p>
                    </div>

                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        5. Transfer Pricing Reforms
                      </span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      The Budget introduces significant refinements to India’s
                      transfer pricing (TP) framework, aligning it more closely
                      with OECD guidelines. Key changes include:
                    </p>
                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                        Block Assessment for Multi-Year Arm’s Length Price
                        (ALP): Taxpayers can now apply the same ALP to similar
                        transactions for a block of three years starting April
                        1, 2026.
                      </li>
                      <li className="mt-2">
                        Simplified Compliance: Enhanced safe harbor provisions
                        and streamlined documentation requirements aim to reduce
                        the administrative burden on businesses.
                      </li>

                      <p
                        className="text-dark servicep"
                        style={{ textAlign: "justify" }}
                      >
                        These reforms are particularly relevant for US
                        multinational companies with Indian subsidiaries or
                        significant transactions with Indian entities.{" "}
                      </p>
                    </div>

                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        6. US-India Bilateral Trade Initiative (BTI)
                      </span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      During Prime Minister Narendra Modi’s visit to Washington
                      in February 2025, both countries expressed their intention
                      to negotiate a Bilateral Trade Initiative (BTI). While
                      specific details are yet to be finalized, this initiative
                      aims to:
                    </p>
                    <div
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                        Address long-running trade disparities
                      </li>
                      <li className="mt-2">
                        Create a more level playing field for businesses in both
                        countries
                      </li>
                      <li className="mt-2">
                        Potentially reduce tariffs and other trade barriers
                      </li>
                    </div>
                  </div>

                  <img
                    src={taxing}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "350px",
                      height: "270px",
                    }}
                  />

                  <p
                    className="text-dark servicep mt-4"
                    style={{ textAlign: "justify" }}
                  >
                    US companies should closely monitor the progress of these
                    negotiations, as they could significantly impact
                    cross-border trade and investment opportunities.
                  </p>
                  <p
                    className="text-dark servicep mt-4"
                    style={{ textAlign: "justify" }}
                  >
                    The landscape of US-India cross-border taxation is evolving
                    rapidly, with both countries taking steps to streamline
                    processes, reduce barriers, and create more favorable
                    conditions for international business. As we navigate
                    through 2025, it is crucial for businesses and individuals
                    engaged in US-India transactions to stay informed about
                    these changes and seek expert advice to optimize their tax
                    strategies.{" "}
                  </p>
                  <p
                    className="text-dark servicep mt-4"
                    style={{ textAlign: "justify" }}
                  >
                    By understanding and leveraging these new provisions,
                    companies can not only ensure compliance but also unlock
                    significant tax benefits and operational efficiencies in
                    their cross-border activities.{" "}
                  </p>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Navigatingusindiacrossborder;
