import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import blogimg from "../../assets/images/blogs/blog15.png";

const FivethBlog = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
      <style jsx>{`
  h4 {
    font-size: 18px;
  }
  h3 {
    font-size: 28px;
  }
  span {
    font-size: 16px;
  }
  p {
    font-size: 16px;
  }
  li {
    font-size: 16px;
    font-weight: 500;
  }
  .mainCard {
    height: 20rem;
  }
  .circle {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
  }
  i {
    color: #072f5f;
  }
  ul {
    padding-left: 17px;
  }
  @media (max-width: 574px) {
    .mTop {
      margin-top: 30px;
    }
    p {
      font-size: 18px;
    }
    .img-fluid {
      min-height: 100px;
    }
    .container-fluid {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @media (min-width: 992px) {
    /* for banner image */
    .bmTop {
      margin-top: 130px;
    }
  }
  @media (max-width: 574px) {
    .banner-image {
      margin-top: 70px; /* Adjust this value for mobile view */
    }
  }
`}</style>

      <section className="">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold", marginTop:'10px' }}
            >
              Navigating the Crypto Landscape: <br /> What Investors Should Know
              in 2024
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Introduction
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      ></span>
                    </div>
                    <p className="text-dark servicep" style={{textAlign:'justify'}}>
                      Cryptocurrency has been a hot topic in the financial world
                      for several years now, and its influence continues to
                      grow. As we move through 2024, it's crucial for investors
                      to understand the current state of the crypto market and
                      its potential implications. Let's dive into some key
                      trends and considerations for navigating the evolving
                      crypto landscape. The Growing Mainstream Acceptance of
                      Crypto Despite its volatility, cryptocurrency is
                      increasingly gaining acceptance in mainstream finance.
                      Here are some indicators:{" "}
                    </p>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Increased Venture Capital Interest :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        Venture capital firms invested a staggering $2.49
                        billion in crypto startups in just the last quarter.
                        This significant influx of capital suggests strong
                        confidence in the future of cryptocurrency.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Regulatory Developments :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        In a move that signals the growing importance of crypto,
                        U.S. President Joe Biden signed a bill requiring all
                        crypto exchanges to report to the IRS. This level of
                        oversight indicates that cryptocurrencies are becoming
                        too significant to ignore.{" "}
                      </li>

                      <li></li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Traditional Investment Options :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        Bitcoin and Ethereum ETFs have now hit the New York
                        Stock Exchange, allowing traders to invest in crypto
                        through more conventional means. This development has
                        sparked increased interest, with searches for "grayscale
                        bitcoin trust" up 900% year-over-year. Global Adoption
                        and Government Interest The crypto trend isn't limited
                        to private investors and companies. We're seeing
                        increased interest from governments worldwide:{" "}
                      </li>

                      <li></li>
                    </ul>
                  </div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      El Salvador's Bold Move :
                    </small>
                  </div>

                  <br />
                  <p className="text-dark servicep">
                    The El Salvador government now requires all local merchants
                    to accept Bitcoin as legal tender.{" "}
                  </p>
                  <img
                    src={blogimg}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "350px",
                      height: "270px",
                    }}
                  />
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Central Bank Digital Currencies :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        Inspired by El Salvador's move, other Central American
                        countries like Honduras and Guatemala are exploring
                        central bank digital currencies.{" "}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        U.S. Public Opinion :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        A recent poll found that 27% of Americans are in favor
                        of adopting Bitcoin. What This Means for Investors As an
                        investor, these developments present both opportunities
                        and risks: Diversification Potential: Cryptocurrency
                        offers a new asset class for portfolio diversification.
                        However, its high volatility means it should be
                        approached with caution.{" "}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Regulatory Considerations :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        Increased government oversight may provide more
                        stability and legitimacy to the crypto market, but it
                        could also lead to regulatory challenges.{" "}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Technological Understanding :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        Investing in crypto requires a basic understanding of
                        blockchain technology and the specific cryptocurrencies
                        you're interested in. Market Volatility: While the
                        potential for high returns exists, so does the risk of
                        significant losses. It's crucial to invest only what you
                        can afford to lose.{" "}
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Looking Ahead :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        The cryptocurrency market is likely to continue evolving
                        rapidly. Here are some things to keep an eye on:
                        Institutional Adoption: Watch for more traditional
                        financial institutions incorporating crypto into their
                        services. Regulatory Developments: Stay informed about
                        changes in crypto regulations, both in the U.S. and
                        globally. Technological Advancements: Keep track of
                        improvements in blockchain technology and new
                        cryptocurrencies entering the market.{" "}
                      </li>
                    </ul>
                  </div>
                  <ul
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign:'justify' }}
                  >
                    <li>
                      Final Thoughts While cryptocurrency presents exciting
                      opportunities, it's important to approach it as part of a
                      balanced, well-researched investment strategy. At Boston
                      Financial Advisory Group, we're here to help you
                      understand these complex markets and make informed
                      decisions that align with your financial goals. Remember,
                      the world of crypto is fast-paced and can be
                      unpredictable. Always do your due diligence, and don't
                      hesitate to seek professional advice before making
                      significant investment decisions in this space.
                    </li>
                  </ul>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FivethBlog;
