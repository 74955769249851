import React, { useState, useEffect } from "react";
// import Contactform1 from "./Contactform1";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faRocket } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import usa from '../../assets/images/flag/USA.png'
import ind from '../../assets/images/flag/download.png'
import uae from '../../assets/images/flag/Flag_of_the_United_Arab_Emirates.svg.png'
// import Contacttagline from './Contacttagline';
import videoBackground from "../../assets/video/boston baneer video.mp4";
import ContactForm from "../Service/ContactForm";
import contact from '../../assets/images/contact/Contact.png'
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Contactus = () => {
  const [activeTab, setActiveTab] = useState("India");
  const [showModal, setShowModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    localStorage.getItem("selectedCountry") || "USA"
  );
  const [isIndia, setIsIndia] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedCountry = localStorage.getItem("selectedCountry");
    if (storedCountry) {
      setSelectedCountry(storedCountry);
      setActiveTab(storedCountry);
    } else {
      localStorage.setItem("selectedCountry", "USA");
      setSelectedCountry("USA");
    }
    setIsIndia(storedCountry === "India");
  }, []);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleServicesClick = (e) => {
    e.preventDefault();
    navigate("/Services");
  };

  const changeCountry = (country) => {
    setSelectedCountry(country);
    setActiveTab(country);
    localStorage.setItem("selectedCountry", country);
    window.location.reload();
  };

  const toggleTab = (tabName) => {
    setActiveTab(tabName);
  };

  const handleTabClick = (tabName) => {
    toggleTab(tabName);
    // changeCountry(tabName);
  };

  const mapUrls = {
    India:
     "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.1341886486666!2d73.76595937417075!3d18.567988067726095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2beca13fe3859%3A0x818548d7ea0eb354!2sCRYSTAL.%20EMPIRE!5e0!3m2!1sen!2sin!4v1725355256214!5m2!1sen!2sin",
    USA: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2947.0216988570032!2d-71.07398157478856!3d42.38468423354688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e370e7a53515c3%3A0xfe39f52016fb276f!2sRegus%20-%20Charlestown%20-%20Schraffts%20Center!5e0!3m2!1sen!2sin!4v1725355114928!5m2!1sen!2sin",
    UAE: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.513297538351!2d55.25769027437642!3d25.1859067321598!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d09681df43%3A0xc222838ff425a54!2sIris%20Bay%20-%20Business%20Bay%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1725354998791!5m2!1sen!2sin",
  };

  const renderContent = () => {
    switch (activeTab) {
      case "India":
      case "USA":
      case "UAE":
        return (

          <div className="w-100 p-6">

          {/* <h4 className="text-center font-bold" style={{color:'#072f5f', fontWeight:'bold', letterSpacing:'0.9px'}}>
            SOUND FINANCE MATTERS
          </h4> */}
          <div className="d-flex my-4" style={{backgroundColor:'#ccc', justifyContent:'space-between'}} >
            <a  style={{textDecoration:'none', fontWeight:'bold' , color:'#072f5f'}}
              className={`d-flex align-items-center px-4 py-2 ${activeTab === "India" ? "text-primary border-bottom border-primary bg-light" : "border-bottom"} `}
              onClick={() => handleTabClick("India")}
            >
              <img src={ind} alt="INDIA Flag" className="" style={{width: '30px', height: '20px'}} />
              India
            </a>
            <a style={{textDecoration:'none', fontWeight:'bold' , color:'#072f5f'}}
              className={`d-flex align-items-center px-4 py-2 ${activeTab === "USA" ? "text-primary border-bottom border-primary bg-light" : "border-bottom"} `}
              onClick={() => handleTabClick("USA")}
            >
              <img src={usa} alt="USA Flag" className="" style={{width: '30px', height: '20px'}} />
              USA
            </a>
            <a style={{textDecoration:'none', fontWeight:'bold' , color:'#072f5f'}}
              className={`d-flex align-items-center px-4 py-2 ${activeTab === "UAE" ? "text-primary border-bottom border-primary bg-light" : "border-bottom"} `}
              onClick={() => handleTabClick("UAE")}
            >
              <img src={uae} alt="UAE Flag" className="" style={{width: '30px', height: '20px'}} />
              UAE
            </a>
          </div>

          {activeTab === "India" && (
            <div className="row mb-4">
              <div className="col-md-6">
                <p className="text-md font-weight-bold">
                  <FontAwesomeIcon icon={faLocationDot} className="mr-2 text-primary" /> Boston Financial Advisory Group Inc.
                </p>
                <p className="text-md">
                  Office No. 203/204, 2nd Floor, Crystal Empire,<br />
                  Near Karnataka Bank, Behind D-Mart, Baner, Pune 411045.
                </p>
                <p className="text-md">
                  <FontAwesomeIcon icon={faPhone} className="mr-2 text-primary" />
                  +91 7420061235
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-md font-weight-bold">
                  <FontAwesomeIcon icon={faLocationDot} className="mr-2 text-primary" /> Boston Financial Advisory Group Inc.# 54, 3rd Floor,
                </p>
                <p className="text-md">
                  13th Main 17th Cross, Sector 6, Behind BDA Shopping Complex, HSR Layout, Bangalore 560 102.
                </p>
                <p className="text-md">
                  <FontAwesomeIcon icon={faPhone} className="mr-2 text-primary" />
                  +91 7420061235
                </p>
              </div>
            </div>
          )}
        
          {activeTab === 'USA' && (
            <div className="row mb-4">
              <div className="col-md-6">
                <p className="text-md font-weight-bold">
                  <FontAwesomeIcon icon={faLocationDot} className="mr-2 text-primary" /> Boston Financial Advisory Group Inc.
                </p>
                <p className="text-md">
                  The Schrafts Center Power House, Office No 341,<br />
                  529 Main Street, Charlestown, MA, USA, 02129
                </p>
                <p className="text-md">
                  <FontAwesomeIcon icon={faPhone} className="mr-2 text-primary" />
                  +1617-886-5181
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-md font-weight-bold">
                  <FontAwesomeIcon icon={faLocationDot} className="mr-2 text-primary" /> 2214 Walnut Street, Mckeesport, PA 15132
                </p>
                <p className="text-md">
                  <FontAwesomeIcon icon={faPhone} className="mr-2 text-primary" />
                  +1617-886-5181
                </p>
              </div>
            </div>
          )}

          {activeTab === 'UAE' && (
            <div className="mb-4">
              <p className="text-md font-weight-bold">
                <FontAwesomeIcon icon={faLocationDot} className="mr-2 text-primary" /> Boston Financial Advisory Group Inc.
              </p>
              <p className="text-md">
                Unit 2540, 25th Floor, Iris Bay, Business Bay, Dubai, UAE
              </p>
              <p className="text-md">
                <FontAwesomeIcon icon={faPhone} className="mr-2 text-primary" /> +971 4 3760840
              </p>
            </div>
          )}
        </div>
        );
      default:
        return null;
    }
  };

  return (
    <>

<style>
  {`
    @media (max-width: 768px) {
      .country-tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px; /* Adjust spacing between tabs */
      }
    }
  `}
</style>


      <Helmet>
        <title>
         Financial Consulting Services | Boston FA Group Advisors
        </title>
        <meta
          name="description"
          content="Choose Boston FA Group for superior financial consulting services. Our business financial advisors offer tailored solutions to meet your goals. Contact our financial advisory firm for expert assistance."
        />
        <meta
          name="keywords"
          content="Financial Advisory Group , Financial advisory firm, Financial consulting firm,  Financial consulting services, Business financial advisor."
        />
      </Helmet>

    <section className="text-gray-600 overflow-hidden w-100 bg-light">

       <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${contact}) no-repeat center`,
            backgroundSize: "cover",
            height: "430px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase"
              style={{ fontWeight: "bold" }}
            >
             Contact Us
            </h3>
          </div>
        </div>

      <div className="container px-5 py-5 mx-auto w-100">
        <div className="lg:w-100 mx-auto d-flex flex-wrap">
          {renderContent()}
          <div className="w-100 lg:h-auto h-64 object-cover object-center rounded">
            <iframe
              src={mapUrls[activeTab]}
              width="100%"
              height="400"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title={`${activeTab} Map`}
            ></iframe>
          </div>
        </div>
      </div>

      <div className="container text-white p-3 my-4 rounded shadow-lg" style={{backgroundColor:'#072f5f'}}>
      <div className="row" >
        <div className="col text-center py-2">
          <a style={{textDecoration:'none'}} href="mailto:info@bostonfagroup.com" className="text-white font-weight-bold">
            info@bostonfagroup.com
          </a>
        </div>
        <div className="col text-center py-2">
          <a style={{textDecoration:'none'}} href="mailto:sales@bostonfagroup.com" className="text-white font-weight-bold">
            sales@bostonfagroup.com
          </a>
        </div>
        <div className="col text-center py-2">
          <a style={{textDecoration:'none'}} href="mailto:careers@bostonfagroup.com" className="text-white font-weight-bold">
            careers@bostonfagroup.com
          </a>
        </div>
      </div>
    </div>
        
    </section>
    <br/>   
    <ContactForm/>
</>

  );
};

export default Contactus;
