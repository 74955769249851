import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

// Import images
import internaltax from '../../assets/images/blogs/InternationalTaxEra1.png'
import fbar2 from '../../assets/images/blogs/FBAR3.png'
import internaltax1 from '../../assets/images/blogs/InternationalTaxEra2.png'
import taxing1 from "../../assets/images/blogs/taxing1.png";
import sustain3 from '../../assets/images/blogs/Sustainability3.png'

const SubBlogs = () => {
  const [hovered, setHovered] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Add event listener to update mobile state on resize
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Mobile view renderer
  const renderMobileView = () => {
    const mobileBlogCards = [
      {
        id: 1,
        to: "/international-tax-era",
        image: internaltax,
        date: "Mar 15, 2025",
        title: "Managing the New Global Minimum International Tax Era: Essential Facts for Multinationals"
      },
      {
        id: 2,
        to: "/fbar-penalties-revealed",
        image: fbar2,
        date: "Mar 10, 2025",
        title: "FBAR Penalties Revealed: What Every Foreign Investor Needs to Know"
      }
    ];

    return (
      <div style={styles.mobileContainer}>
        <div className="pb-2 mb-3">
          <h3 className="text-center blog-title" style={styles.title}>
            Insights from Boston Financialg
          </h3>
        </div>

        <div style={styles.mobileCardContainer}>
          {mobileBlogCards.map((card) => (
            <Link
              key={card.id}
              to={card.to}
              style={styles.mobileCard}
              onMouseEnter={() => setHovered(card.id)}
              onMouseLeave={() => setHovered(null)}
            >
              <div
                style={{
                  ...styles.mobileCardImage,
                  backgroundImage: `url(${card.image})`,
                }}
              >
                <div
                  className={`cardOverlay ${
                    hovered === card.id ? "overlayVisible" : ""
                  }`}
                  style={{
                    ...styles.mobileCardOverlay,
                    ...(hovered === card.id ? styles.overlayVisible : {}),
                  }}
                ></div>
                <div style={styles.cardDateContainer}>
                  <div style={styles.cardDate}>{card.date}</div>
                  <div style={styles.cardDateLine}></div>
                </div>
                <div style={styles.mobileCardTitle}>
                  {card.title}
                </div>
              </div>
            </Link>
          ))}
        </div>

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Link to="/blogs">
            <Button
              className="buttonUnique read-more-button"
              variant="contained"
              color="primary"
              style={{ 
                backgroundColor: "#072f5f",
                padding: "8px 24px",
                fontSize: "16px",
                minWidth: "120px"
              }}
            >
              Read More
            </Button>
          </Link>
        </div>
      </div>
    );
  };

  // Desktop view renderer
  const renderDesktopView = () => {
    // Your existing desktop view code (copied from the original component)
    return (
      <div
        style={{
          ...styles.cardContainer,
          marginBottom: "50px",
          marginTop: "20px",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "70px",
          marginRight: "auto",
        }}
      >
        {/* Your existing desktop view code */}
        {/* (paste the original render code here) */}
      </div>
    );
  };

  return isMobile ? renderMobileView() : renderDesktopView();
};

const styles = {
  // Existing styles from the original component
  ...{
    // Add mobile-specific styles
    mobileContainer: {
      padding: "20px",
      backgroundColor: "#f4f4f4"
    },
    mobileCardContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      alignItems: "center"
    },
    mobileCard: {
      width: "100%",
      maxWidth: "400px",
      position: "relative",
      overflow: "hidden",
      cursor: "pointer"
    },
    mobileCardImage: {
      width: "100%",
      height: "300px",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
      borderRadius: "15px"
    },
    mobileCardOverlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "transparent",
      transition: "background-color 0.3s ease"
    },
    mobileCardTitle: {
      position: "absolute",
      bottom: "10px",
      left: "10px",
      right: "10px",
      color: "white",
      fontSize: "18px",
      fontWeight: "600",
      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)"
    }
  }
};

export default SubBlogs;