import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import goalnew from '../../assets/FlipImages/goalimg.png';
import bggoal from '../../assets/FlipImages/bggoal.png';

const Visionmissiongoal = () => {
  const [activeTab, setActiveTab] = useState("vision");

  const renderContent = () => {
    switch (activeTab) {
      case "vision":
        return (
          <p className="leading-relaxed">
            At the core of our success story lies the unwavering commitment to excellence, which permeates every interaction and is fortified by our five pillars: advice, outsourcing services, domain knowledge, processes, and technology.
          </p>
        );
      case "mission":
        return (
          <p className="leading-relaxed">
            To be a leading outsourcing and advisory partner for the SME sector worldwide that provides analytical information, technology, and domain expertise quickly and cost effectively.
          </p>
        );
      case "quality statement":
        return (
          <p className="leading-relaxed">
            Excel in providing financial outsourcing, consulting and advisory services leveraging our expertise and technology-driven process leading to customer and partner delight.
          </p>
        );
      default:
        return null;
    }
  };

  return (
    <section
      className="text-gray-600 body-font overflow-hidden"
      style={{
        backgroundImage: `url(${bggoal})`,
        backgroundSize: "contain",
        backgroundPosition: "top left",
        backgroundRepeat: "no-repeat",
        padding: "20px",
      }}
    >
      <Container className="px-5 py-24 mx-auto">
        <Row className="lg:w-4/5 mx-auto flex flex-wrap align-items-center">
          {/* Text Section */}
          <Col
            lg={6}
            xs={12}
            className="d-flex flex-column justify-content-center text-center text-lg-start mb-6 lg:mb-0"
          >
            <h2 className="unique-display-4 unique-font-weight-bold" style={{ color: "#072f5f", fontSize: "35px", fontWeight: "bold", textAlign: "center" }}>
              Our Vision & Mission
            </h2>

            {/* Tabs (Keep in one row) */}
            <div className="d-flex flex-wrap justify-content-center align-items-center mt-2 mb-2 text-center">
              {["vision", "mission", "quality statement"].map((tab) => (
                <span
                  key={tab}
                  className={`py-2 px-2 text-lg cursor-pointer ${activeTab === tab ? "text-indigo-500" : "text-gray-500"}`}
                  onClick={() => setActiveTab(tab)}
                  style={{
                    borderBottom: activeTab === tab ? "2px solid #072f5f" : "2px solid #d1d5db",
                    margin: "0 15px",
                    cursor: "pointer",
                    textAlign: "center"
                  }}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </span>
              ))}
            </div>

            {/* Centered Content Section */}
            <div className="content-area">
              {renderContent()}
            </div>
          </Col>

          {/* Image Section */}
          <Col
            lg={6}
            xs={12}
            className="d-flex justify-content-center align-items-center"
          >
            <img alt="goal" className="object-cover rounded" src={goalnew} style={{ width: "auto", height: "400px", padding:'10px' }} />
          </Col>
        </Row>
      </Container>

      {/* Internal CSS for Mobile View */}
      <style>
        {`
          @media (max-width: 768px) {
            /* Ensure content is centered */
            .content-area {
              text-align: center;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              min-height: 150px;
            }

            /* Ensure tabs remain in a row */
            .d-flex.flex-wrap.justify-content-center.align-items-center {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
            }

            /* Ensure each tab has proper spacing in mobile */
            .d-flex.flex-wrap.justify-content-center.align-items-center span {
              margin: 5px 10px;
            }
          }
        `}
      </style>
    </section>
  );
};

export default Visionmissiongoal;