import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import blog13 from "../../assets/images/blogs/blog13.jfif";
import latestblog from "../../assets/images/blogs/latestblogimg1.jpeg";
import blogee1 from "../../assets/images/blogs/blogee1.jpg";
import blogee2 from "../../assets/images/blogs/blogee2.jpg";
import blogee3 from "../../assets/images/blogs/blogee3.jpg";
import blogee4 from "../../assets/images/blogs/blogee4.jpg";
import blogee5 from "../../assets/images/blogs/blogee5.jpg";

const Increasingincidenceoffinancial = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
      <style jsx>{`
  h4 {
    font-size: 18px;
  }
  h3 {
    font-size: 28px;
  }
  span {
    font-size: 16px;
  }
  p {
    font-size: 16px;
  }
  li {
    font-size: 16px;
    font-weight: 500;
  }
  .mainCard {
    height: 20rem;
  }
  .circle {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
  }
  i {
    color: #072f5f;
  }
  ul {
    padding-left: 17px;
  }
  @media (max-width: 574px) {
    .mTop {
      margin-top: 30px;
    }
    p {
      font-size: 18px;
    }
    .img-fluid {
      min-height: 100px;
    }
    .container-fluid {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @media (min-width: 992px) {
    /* for banner image */
    .bmTop {
      margin-top: 130px;
    }
  }
  @media (max-width: 574px) {
    .banner-image {
      margin-top: 70px; /* Adjust this value for mobile view */
    }
  }
`}</style>

      <section className="">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold", marginTop:'10px'  }}
            >
              The Increasing Incidence of Financial <br/> Statement Retractions
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Introduction
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      ></span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      Over the past few years, an alarming trend emerged within
                      the United States corporate landscape: a most unusual rise
                      in the incidence of financial statements being withdrawn
                      due to accounting mistakes.
                    </p>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      This development has generated maximum concerns amongst
                      boardrooms and has left maximum doubts amongst investors,
                      regulators, and financial advisors. As we discuss this
                      critical issue, we will delve into its causes,
                      implications, and most importantly, how to prevent such
                      costly errors by corporations.
                    </p>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Alarming Statistics Current statistics paint a dismal
                        picture:
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc" }}
                    >
                      <li>
                        In 2023, over 10,000 retractions were recorded in
                        different scientific disciplines, of which a significant
                        majority was related to accounting errors. From 2000 to
                        2020, the fourfold increase in biomedical science paper
                        retractions indicates that a trend of growing error and
                        fraud is widespread.
                      </li>
                      <li>
                        Many top U.S. companies have been forced to adjust their
                        financial statements, thus shaking investor confidence
                        and eroding market capitalization.
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Root Causes of the Accounting Error Explosion
                      </span>
                    </div>
                  </div>
                  <ul
                    className="text-dark servicep"
                    // style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li className="mt-2">
                      1. Complexity of Financial Reporting Standards: The world
                      of accounting standards, especially in terms of
                      implementation of new FASB standards, makes financial
                      reporting much more complex.
                    </li>
                    <li className="mt-2">
                      2. Technological Disruptions: While technology has
                      streamlined most of the accounting processes, it has now
                      introduced new challenges as well. Cybersecurity threats,
                      data integrity issues, and learning curves with new
                      financial software all lead to potential errors.
                    </li>
                    <li className="mt-2">
                      3. The pressure to meet or beat quarterly earnings
                      projections can sometimes lead to 'aggressive or even
                      misleading accounting practices'. Weak internal control
                      systems, especially for growth companies, may also result
                      in failure of oversight and large misstatements.
                      Consequences of withdrawn financial statements are also
                      pertinent. Loss of Investor Confidence: Restatements
                      normally lead to massive declines in stock prices and
                      often completely destroy a company's reputation in the
                      investment community.
                    </li>
                  </ul>

                  <div>
                    {/* <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Conclusion:
                      </span>
                    </div> */}
                  </div>
                  <ul
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li className="mt-4">
                      Investigations and Legal Fees: Normally, the SEC launches
                      an investigation after major restatements, which normally
                      leads to heavy fines and long legal procedures against the
                      business.
                    </li>
                    <li className="mt-4">
                      Forced Resignation: CFOs and other executives normally are
                      forced out after major accounting flaws that rock the
                      organizational leadership.
                    </li>
                    <li className="mt-4">
                      Higher Cost of Audit: Companies that have restated their
                      financials face much harsher and costlier auditing
                      processes the following years.
                    </li>
                  </ul>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Internal Controls: Best Practices of Companies
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                        1. Strengthen Internal Controls: Put internal controls
                        appropriately and have them reviewed regularly for their
                        efficacy as well. This includes the clear segregation of
                        duties and successive levels of review of the financial
                        statements.
                      </li>
                      <li className="mt-2">
                        2. Invest in Continuous Education: Ensure that your
                        financial team is up to date on latest accounting
                        standards and best practice through continuous
                        professional development.
                      </li>
                      <li className="mt-2">
                        3. Use Technology Wisely: Implement advanced accounting
                        software using compliance checks and audit trails.
                        Proper training and oversight, however, will be
                        necessary.
                      </li>
                      <li className="mt-2">
                        4. Make Integrity Incentive: Encourage a corporate
                        culture that motivates the pursuit of accuracy and
                        transparency rather than the meeting of short-term
                        financial numbers.
                      </li>
                      <li className="mt-2">
                        5. Routine Internal Audits: Conduct routine internal
                        audits to detect and correct any errors before they
                        become a material misstatement.{" "}
                      </li>
                      <li className="mt-2">
                        6 External Expertise: Engage in financial advisory firms
                        specializing in corporate accounting and compliance to
                        complement the oversight and expertise. The Future
                        Accepting Responsibility and Accuracy.{" "}
                      </li>
                    </ul>
                  </div>
                  <img
                    src={blogee4}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "350px",
                      height: "270px",
                    }}
                  />
                  <div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        The Business World is getting increasingly complicated,
                        and the need for accurate reporting cannot be overstated
                        anymore. Such business protects themselves from severe
                        restatement consequences and also provides a fundamental
                        basis for building investor, regulator, and public
                        trust. Professional advisory is highly beneficial when
                        firms would like to improve their process in financial
                        reporting and to reduce the likelihood of accounting
                        errors. Boston Financial Advisory Group renders expert
                        professional services in areas of financial compliance,
                        the optimization of internal controls, and management of
                        risk. Your company must not be on the increasing count
                        of financial restatements. Step out ahead of schedule
                        today to be confident about accuracy and reliability of
                        your firm's financial reportings.
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
Want stronger financial reporting procedures? 
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                      Talk to Boston Financial Advisory Group for a review tailored to achieve financial integrity.
                      </li>                     
                    </ul>
                  </div>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Increasingincidenceoffinancial;
