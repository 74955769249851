import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import blog13 from "../../assets/images/blogs/blog13.jfif";
import latestblog from "../../assets/images/blogs/latestblogimg1.jpeg";
import taxtime1 from "../../assets/images/blogs/taxtime2.png";
import taxtime2 from "../../assets/images/blogs/taxtime3.png";

const InternationalTaxEra = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
      <style jsx>{`
        h4 {
          font-size: 18px;
        }
        h3 {
          font-size: 28px;
        }
        span {
          font-size: 16px;
        }
        p {
          font-size: 16px;
        }
        li {
          font-size: 16px;
          font-weight: 500;
        }
        .mainCard {
          height: 20rem;
        }
        .circle {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          overflow: hidden;
        }
        i {
          color: #072f5f;
        }
        ul {
          padding-left: 17px;
        }
        @media (max-width: 574px) {
          .mTop {
            margin-top: 30px;
          }
          p {
            font-size: 18px;
          }
          .img-fluid {
            min-height: 100px;
          }
          .container-fluid {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        @media (min-width: 992px) {
          /* for banner image */
          .bmTop {
            margin-top: 130px;
          }
        }
        @media (max-width: 574px) {
          .banner-image {
            margin-top: 70px; /* Adjust this value for mobile view */
          }
        }
      `}</style>

      <section className="">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold", marginTop: "10px" }}
            >
              Managing the New Global <br /> Minimum International Tax Era
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Introduction
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      ></span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      As we move further into 2025, multinational companies
                      (MNCs) are faced with an ever-changing global tax
                      environment. The implementation of the global minimum tax
                      has brought about a new era of corporate taxation, with
                      implications for tax planning and compliance requirements
                      that are far-reaching. This manual has been put together
                      to help MNCs respond to these complex situations and
                      adjust their tactics accordingly.
                    </p>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Learning about the Global Minimum Tax{" "}
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        Minimum global tax, one of the elements of the OECD's
                        Pillar Two proposal, has been created to ensure that
                        large multinational groups pay a minimum effective tax
                        rate of 15% in all jurisdictions where they operate.
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Following are the key points to know:
                      </span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      The rules apply to multinational groups with a
                      consolidated annual turnover exceeding €750 million.{" "}
                    </p>
                  </div>
                  <ul
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li>
                      Effective Date: More than 40 jurisdictions, including the
                      European Union, implemented the global minimum tax in
                      2024.
                    </li>
                    <li>
                      Top-Up Tax: Where a company's effective tax rate is below
                      15% in any place, extra "top-up tax" can be charged to the
                      minimum rate.
                    </li>
                  </ul>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                          textAlign: "justify",
                        }}
                      >
                        Implications for Multinational Corporations
                      </span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      The global minimum tax has sweeping implications for tax
                      planning and MNCs' operations:
                    </p>
                  </div>
                  <ul
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li>
                      Sophistication in Tax Planning: MNCs need to reassess
                      their global tax frameworks to ensure continued compliance
                      while maximizing their effective tax rates. Increased
                      Compliance Requirement: Companies need to prepare for new
                      reporting obligations and potential changes to their
                      internal processes and procedures. Effect on Investment
                      Decisions: The new regulations may have an effect on
                      investment decisions regarding where to place operations
                      and investments worldwide. Cash Flow Consequences:
                      Multinational companies will need to modify their cash
                      management strategies to provision for future top-up tax
                      payments.
                    </li>
                    <li>
                      Compliance Requirements and Strategies In order to
                      successfully navigate the new world of global minimum
                      taxation.
                    </li>
                  </ul>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                          textAlign: "justify",
                        }}
                      >
                        MNCs must pursue the following strategies:{" "}
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        Thorough Tax Review: Conduct a thorough review of your
                        international tax framework to determine potential areas
                        of exposure in response to the new rule.
                      </li>
                      <li>
                        Utilize Safe Harbors: Take advantage of the streamlined
                        compliance structures, or "safe harbors," offered by
                        2024-2026 that may relieve compliance burdens.
                      </li>
                      <li>
                        Revisit Intercompany Agreements: Revisit existing
                        intercompany agreements to equitably distribute tax
                        obligations within the enterprise.{" "}
                      </li>
                      <li>
                        Enhancing Data Management: It is essential to invest in
                        quality data management systems to provide timely and
                        accurate reporting across all jurisdictions.
                      </li>
                      <li>
                        Take into consideration Substance Requirements: Assess
                        the substance of activities in low-tax jurisdictions to
                        determine whether they can be exempted from top-up tax.{" "}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Expert Insight: Managing Political Risk{" "}
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        It must be pointed out that the international tax
                        environment remains subject to political upheavals. A
                        case in point is the recent shift in the U.S.
                        administration, which has prompted a re-consideration on
                        the country's stance on the OECD Global Tax Deal. Global
                        companies need to keep abreast of such changes and be
                        willing to modify their strategies as and when required.
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Preparation for the future.{" "}
                      </span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      As the global minimum tax regime continues to develop,
                      multinational companies need to be agile and proactive
                      with their international taxation approach.
                    </p>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        The following are some of the main steps to be
                        considered:
                      </li>
                      <li>
                        Scenario Planning: Develop a number of tax scenarios to
                        understand the potential implications of the global
                        minimum tax for your organization under different sets
                        of circumstances.{" "}
                      </li>
                      <li>
                        Stakeholder Communication: Shareholders, board members,
                        and other key stakeholders should be notified of the
                        likely effect of the new taxation system on the
                        financial position of the company.{" "}
                      </li>
                      <li>
                        Continuous Monitoring: Stay in touch with what is going
                        on in the world's tax legislations and prepared to shift
                        your strategies whenever necessary.
                      </li>
                      <li>
                        Invest in Expertise: Consider building in-house
                        capabilities or engaging external tax specialists who
                        possess international taxation and global minimum tax
                        expertise.
                      </li>
                    </ul>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      Compliance with the new era of a global minimum tax is
                      challenging and presents opportunities for multinational
                      corporations.
                    </p>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      By remaining current, being proactive, and being strategic
                      in one's approach, it is possible to stay compliant while
                      optimizing the global tax scenario.{" "}
                    </p>
                  </div>

                  <img
                    src={taxtime2}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "350px",
                      height: "270px",
                    }}
                  />
                  <div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        We get it at Boston Financial Advisory Group – the
                        complexities of global taxation and the singular
                        challenges of the multinational corporation. Our staff
                        of seasoned advisors can assist you through the nuances
                        of the global minimum tax, the creation of resilient
                        compliance plans, and the realization of your optimized
                        global tax arrangement.
                      </li>
                      <li>
                        Don't get caught flat-footed in the evolving landscape
                        of taxation – reach out to us today so your organization
                        is ready for the future of international taxation.
                      </li>
                    </ul>
                  </div>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InternationalTaxEra;
