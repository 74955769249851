import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import accounting from "../../../assets/images/service/Accounting.png";
import { Link } from "react-router-dom";
import videoBackground from "../../../assets/video/boston baneer video.mp4";
import { Helmet } from "react-helmet";

const AccountingTransactionProcessing = () => {
  const [activeKey1, setActiveKey1] = useState("0");
  const [activeKey2, setActiveKey2] = useState("0");
  const [hoveredLink, setHoveredLink] = useState(null);
  const [isIndia, setIsIndia] = useState(false);
  const [isUSA, setIsUSA] = useState(false);
  const [isUAE, setIsUAE] = useState(false);

  useEffect(() => {
    const selectedCountry = localStorage.getItem("selectedCountry");
    setIsIndia(selectedCountry === "India");
    setIsUSA(selectedCountry === "USA");
    setIsUAE(selectedCountry === "UAE");
  }, []);

  const customToggle1 = (eventKey) => {
    setActiveKey1(eventKey === activeKey1 ? null : eventKey);
  };

  const customToggle2 = (eventKey) => {
    setActiveKey2(eventKey === activeKey2 ? null : eventKey);
  };

  const headerStyle = {
    color: "#000",
    textAlign: "center",
    fontWeight: "600",
    textDecoration: "none",
  };

  const linkStyle = (link) => ({
    textDecoration: "none",
    color: "#000",
    fontWeight: hoveredLink === link ? "bold" : "normal",
  });

  const handleMouseEnter = (link) => {
    setHoveredLink(link);
  };

  const handleMouseLeave = () => {
    setHoveredLink(null);
  };
  
  <style>{`
    .card {
      border: none;
      height: auto; /* Allow height to adjust based on content */
    }

    .shli {
      display: flex;
      align-items: center;
    }

    .shli i {
      margin-right: -20px;
    }

    li {
      padding-bottom: 0.8rem;
      cursor: pointer;
    }

    li:hover {
      font-weight: 600;
    }

    .faq-accordion-3 .accordion .card .card-header a {
      line-height: 40px; /* Adjusted for mobile */
      padding: 0 0 0 10px; /* Adjusted for mobile */
      text-decoration: none;
      font-weight: 600;
      font-size: 14px; /* Adjusted for mobile */
      width: 100%;
      color: #072F5F;
      position: relative;
    }

    p {
      font-weight: 500;
    }

    h1 {
      font-size: 36px !important; /* Adjusted for mobile */
      border-bottom: 3px solid white;
      display: inline-block;
    }

    h3 {
      font-size: 24px !important; /* Adjusted for mobile */
    }

    .accordion-container {
      max-height: 300px; /* Adjusted for mobile */
      overflow-y: auto;
      margin-bottom: 1rem; /* Adjusted for mobile */
    }

    .page-content {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }

    .main-content {
      flex: 1;
    }

    /* Media query for responsiveness */
    @media (max-width: 768px) {
      .banner-image {
        height: 200px !important; /* Adjusted for mobile */
      }

      h3 {
        font-size: 20px !important; /* Adjusted for mobile */
      }

      h1 {
        font-size: 28px !important; /* Adjusted for mobile */
      }

      .row {
        flex-direction: column; /* Stack columns vertically */
      }

      .col-lg-6, .col-lg-8 {
        width: 100% !important; /* Full width on mobile */
        margin-left: 0 !important; /* Remove left margin */
      }

      .serviceul {
        margin-left: 0 !important; /* Remove left margin for list items */
      }

      .shli {
        margin-left: 0 !important; /* Remove left margin for list items */
      }

      .faq-accordion-3 .accordion .card .card-header a {
        font-size: 14px !important; /* Adjusted for mobile */
        line-height: 30px !important; /* Adjusted for mobile */
      }

      p {
        font-size: 14px !important; /* Adjusted for mobile */
        padding-left: 0 !important; /* Remove left padding */
      }

      .servicep {
        padding-left: 0 !important; /* Remove left padding */
      }

      .shli a {
        font-size: 14px !important; /* Adjusted for mobile */
        padding: 10px 0; /* Add padding for better tappability */
      }

      .accordion-header {
        padding: 10px 0; /* Add padding for better tappability */
      }

      .container-fluid {
        padding-left: 15px !important; /* Add padding for mobile */
        padding-right: 15px !important; /* Add padding for mobile */
      }

      .col-lg-8 {
        padding-left: 0 !important; /* Remove left padding */
        padding-right: 0 !important; /* Remove right padding */
      }

      .accordion-body {
        padding: 10px !important; /* Adjusted for mobile */
      }

      .accordion-body p {
        font-size: 14px !important; /* Adjusted for mobile */
      }

      .main-content {
        padding: 10px !important; /* Adjusted for mobile */
      }

      .page-content {
        padding: 10px !important; /* Adjusted for mobile */
      }
    }
  `}</style>
  return (
    <>
      <Helmet>
        <title>Online Accounting: Small Business Outsourcing Solutions</title>
        <meta
          name="description"
          content="Need an accounting consultant? Our USA-based financial accounting services offer expert outsourcing solutions for small businesses. Let our skilled team handle your accounting needs, freeing you to focus on growing your business."
        />
        <meta
          name="keywords"
          content="Online Accounting Services, Small business accounting,  financial accounting services ,  Business advisory services, accounting services for usa businesses."
        />
          <meta name="author" content="Boston Financial" />
        <meta name="robots" content="follow, index" />
        <link rel="canonical" href="https://bostonfagroup.com" />
      </Helmet>

      <style>{`
        .card {
          // background: #072F5F1A;
          border: none;
          height: 500px
        }
        .shli {
          display: flex;
          align-items: center;
        }

        .shli i {
          margin-right: -20px; /* Adjust as needed for spacing */
        }

        li {
          padding-bottom: 0.8rem;
          cursor: pointer;
        }

        li:hover {
          font-weight: 600;
        }

        .faq-accordion-3 .accordion .card .card-header a {
          line-height: 60px;
          padding: 0 0 0 20px;
          text-decoration: none;
          font-weight: 600;
          font-size: 16px;
          width: 100%;
          color: #072F5F;
          position: relative;
        }

        p {
          font-weight: 500;
        }

        h1 {
          font-size: 48px !important;
          border-bottom: 3px solid white;
          display: inline-block;
        }

        h4 {
          font-size: 18px;
        }

        h3 {
          font-size: 28px;
        }

        .accordion-container {
          max-height: 500px;
          overflow-y: auto;  
          margin-bottom: 2rem; 
        }

        .page-content {
          display: flex;
          flex-direction: column;
          min-height: 100vh;
        }

        .main-content {
          flex: 1;
        }

        /* Media query for responsiveness */
        @media (max-width: 768px) {
          .accordion-container {
            max-height: 300px;
          }

          .banner-image {
            height: 300px;
          }

          h3 {
            font-size: 24px;
          }

          h1 {
            font-size: 36px;
          }
        }
      `}</style>

      <section className="page-content">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${accounting}) no-repeat center`,
            backgroundSize: "cover",
            height: "430px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase"
              style={{ fontWeight: "bold" }}
            >
              Accounting & Transaction Processing
            </h3>
          </div>
        </div>

        <div className="main-content">
          <div className="d-flex justify-content-center align-items-center banner-image">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h3 className="text-white text-uppercase text-center">
                Accounting & Transaction Processing
              </h3>
            </div>
          </div>
          <div className="container-fluid d-md-flex justify-content-center align-content-center bg-white pb-70">

            <div className="row w-100">
              <div
                className="col-lg-6"
                style={{ width: "90%", marginLeft: "22px" }}
              >
                <div className="about-faq-area-custom horizon-hrms bg_cover">
                  <div className="faq-accordion-3 pt-0 accordion-container">
                    <div className="accordion" id="accordionExample1">
                      <div className="card" style={{ width: "100%" }}>
                        <Accordion
                          activeKey={activeKey1}
                          onSelect={customToggle1}
                          flush
                        >
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <span style={headerStyle}>
                                Accounting and Transaction Processing
                              </span>
                            </Accordion.Header>

                            <Accordion.Body>
                              <div
                                id="collapseSev"
                                className="collapse show"
                                aria-labelledby="headingSev"
                                data-parent="#accordionExample1"
                              >
                                <div className="card-body bg-white">
                                  <div>
                                    <ul className="text-dark serviceul">
                                      <li className="shli">
                                        <Link
                                          to="/accountspayable"
                                          style={linkStyle("/accountspayable")}
                                          onMouseEnter={() =>
                                            handleMouseEnter("/accountspayable")
                                          }
                                          onMouseLeave={handleMouseLeave}
                                        >
                                          <i className="far fa-angle-double-right pr-1 pb-3 "></i>
                                          Accounts Payable
                                        </Link>
                                      </li>
                                      <li className="shli">
                                        <Link
                                          to="/accountreceivable"
                                          style={linkStyle(
                                            "/accountreceivable"
                                          )}
                                          onMouseEnter={() =>
                                            handleMouseEnter(
                                              "/accountreceivable"
                                            )
                                          }
                                          onMouseLeave={handleMouseLeave}
                                        >
                                          <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                          Accounts Receivable
                                        </Link>
                                      </li>

                                      <li className="shli">
                                        <Link
                                          to="/fixed-assets-accounting"
                                          style={linkStyle(
                                            "/fixed-assets-accounting"
                                          )}
                                          onMouseEnter={() =>
                                            handleMouseEnter(
                                              "/fixed-assets-accounting"
                                            )
                                          }
                                          onMouseLeave={handleMouseLeave}
                                        >
                                          <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                          Fixed Assets Accounting
                                        </Link>
                                      </li>

                                      <li className="shli">
                                        <Link
                                          to="/financial-reporting"
                                          style={linkStyle(
                                            "/financial-reporting"
                                          )}
                                          onMouseEnter={() =>
                                            handleMouseEnter(
                                              "/financial-reporting"
                                            )
                                          }
                                          onMouseLeave={handleMouseLeave}
                                        >
                                          <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                          Financial Reporting
                                        </Link>
                                      </li>

                                      <li className="shli">
                                        <Link
                                          to="/budgeting-and-variance-analysis"
                                          style={linkStyle(
                                            "/budgeting-and-variance-analysis"
                                          )}
                                          onMouseEnter={() =>
                                            handleMouseEnter(
                                              "/budgeting-and-variance-analysis"
                                            )
                                          }
                                          onMouseLeave={handleMouseLeave}
                                        >
                                          <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                          Budgeting & variance analysis
                                        </Link>
                                      </li>

                                      <li className="shli">
                                        <Link
                                          to="/reconciliation-service"
                                          style={linkStyle(
                                            "/reconciliation-service"
                                          )}
                                          onMouseEnter={() =>
                                            handleMouseEnter(
                                              "/reconciliation-service"
                                            )
                                          }
                                          onMouseLeave={handleMouseLeave}
                                        >
                                          <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                          Reconciliation Service
                                        </Link>
                                      </li>

                                      <li className="shli">
                                        <Link
                                          to="/consolidation-reporting-gaap-conversion"
                                          style={linkStyle(
                                            "/consolidation-reporting-gaap-conversion"
                                          )}
                                          onMouseEnter={() =>
                                            handleMouseEnter(
                                              "/consolidation-reporting-gaap-conversion"
                                            )
                                          }
                                          onMouseLeave={handleMouseLeave}
                                        >
                                          <i className="far fa-angle-double-right pt-1 pr-1"></i>
                                          Consolidation Reporting & GAAP
                                          Conversion
                                        </Link>
                                      </li>
                                      {!isIndia && (
                                        <li className="shli">
                                          <Link
                                            to="/consolidation-reporting-gaap-conversion"
                                            style={linkStyle(
                                              "/consolidation-reporting-gaap-conversion"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/consolidation-reporting-gaap-conversion"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i
                                              className="far fa-angle-double-right pt-1 pb-3 pr-1"
                                              style={{ marginTop: "10px" }}
                                            ></i>
                                            Small Business Accounting
                                          </Link>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              <span style={headerStyle}>
                                Tax & Regulatory Compliances
                              </span>
                            </Accordion.Header>

                            <Accordion.Body>
                              <div
                                id="collapseSev"
                                className="collapse show"
                                aria-labelledby="headingSev"
                                data-parent="#accordionExample1"
                              >
                                <div className="card-body bg-white">
                                  <div>
                                    <ul className="text-dark serviceul">
                                      {!isUSA && !isUAE && (
                                        <li className="shli">
                                          <Link
                                            to="/income-tax"
                                            style={linkStyle("/income-tax")}
                                            onMouseEnter={() =>
                                              handleMouseEnter("/income-tax")
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Income Tax
                                          </Link>
                                        </li>
                                      )}
                                      {!isIndia && !isUAE && (
                                        <li className="shli">
                                          <Link
                                            to="/Individual-tax-return"
                                            style={linkStyle(
                                              "/Individual-tax-return"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/Individual-tax-return"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Individual Tax Return
                                          </Link>
                                        </li>
                                      )}
                                      {!isIndia && !isUAE && (
                                        <li className="shli">
                                          <Link
                                            to="/Corporate-tax-return"
                                            style={linkStyle(
                                              "/Corporate-tax-return"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/Corporate-tax-return"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Corporate Tax Return
                                          </Link>
                                        </li>
                                      )}
                                      {!isIndia && !isUAE && (
                                        <li className="shli">
                                          <Link
                                            to="/Sales-and-usa-tax"
                                            style={linkStyle(
                                              "/Sales-and-usa-tax"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/Sales-and-usa-tax"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Sales & USA Tax
                                          </Link>
                                        </li>
                                      )}
                                      {!isUSA && !isUAE && (
                                        <li className="shli">
                                          <Link
                                            to="/goods-and-service-tax"
                                            style={linkStyle(
                                              "/goods-and-service-tax"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/goods-and-service-tax"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Goods and Service Tax
                                          </Link>
                                        </li>
                                      )}
                                      {!isUSA && !isUAE && (
                                        <li className="shli">
                                          <Link
                                            to="/corporate-secretarial-compliance"
                                            style={linkStyle(
                                              "/corporate-secretarial-compliance"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/corporate-secretarial-compliance"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Corporate Secretarial Compliance
                                          </Link>
                                        </li>
                                      )}
                                      {!isUSA && !isUAE && (
                                        <li className="shli">
                                          <Link
                                            to="/Fema-compliance"
                                            style={linkStyle(
                                              "/Fema-compliance"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/Fema-compliance"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            FEMA Compliance
                                          </Link>
                                        </li>
                                      )}

                                      {!isUSA && !isIndia && (
                                        <li className="shli">
                                          <Link
                                            to="/vat-advisory"
                                            style={linkStyle("/vat-advisory")}
                                            onMouseEnter={() =>
                                              handleMouseEnter("/vat-advisory")
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            VAT Advisory
                                          </Link>
                                        </li>
                                      )}

                                      {!isUSA && !isIndia && (
                                        <li className="shli">
                                          <Link
                                            to="/corporate-tax-advisory"
                                            style={linkStyle(
                                              "/corporate-tax-advisory"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/corporate-tax-advisory"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Corporate Tax Advisory
                                          </Link>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              <span style={headerStyle}>Payroll Advisory </span>
                            </Accordion.Header>

                            <Accordion.Body>
                              <div
                                id="collapseSev"
                                className="collapse show"
                                aria-labelledby="headingSev"
                                data-parent="#accordionExample1"
                              >
                                <div className="card-body bg-white">
                                  <div>
                                    <ul
                                      className="text-dark serviceul"
                                      style={{ marginLeft: "-33px" }}
                                    >
                                      {!isUSA && !isUAE && (
                                        <li className="shli">
                                          <Link
                                            to="/payroll-processing"
                                            style={linkStyle(
                                              "/payroll-processing"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/payroll-processing"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Payroll - Compensation & Benefit
                                            Management
                                          </Link>
                                        </li>
                                      )}
                                      {!isIndia && (
                                        <li className="shli">
                                          <Link
                                            to="/payroll-and-processing"
                                            style={linkStyle(
                                              "/payroll-and-processing"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/payroll-and-processing"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Payroll Processing
                                          </Link>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>

                          {!isUSA && !isIndia && (
                            <Accordion.Item eventKey="5">
                              <Accordion.Header>
                                <span style={headerStyle}>
                                  Audit & Assurance Support (UAE)
                                </span>
                              </Accordion.Header>

                              <Accordion.Body>
                                <div
                                  id="collapseSev"
                                  className="collapse show"
                                  aria-labelledby="headingSev"
                                  data-parent="#accordionExample1"
                                >
                                  <div className="card-body bg-white">
                                    <div>
                                      <ul className="text-dark serviceul">
                                        <li className="shli">
                                          <Link
                                            to="/statutory-audit"
                                            style={linkStyle(
                                              "/statutory-audit"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/statutory-audit"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Statutory Audit for Freezones &
                                            Mainland
                                          </Link>
                                        </li>

                                        <li className="shli">
                                          <Link
                                            to="/internal-audit"
                                            style={linkStyle("/internal-audit")}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/internal-audit"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Internal Audit
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          )}

                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                              <span style={headerStyle}>
                                Consulting & Advisory
                              </span>
                            </Accordion.Header>

                            <Accordion.Body>
                              <div
                                id="collapseSev"
                                className="collapse show"
                                aria-labelledby="headingSev"
                                data-parent="#accordionExample1"
                              >
                                <div className="card-body bg-white">
                                  <div>
                                    <ul className="text-dark serviceul">
                                      {!isUAE && (
                                        <li className="shli">
                                          <Link
                                            to="/business-setup"
                                            style={linkStyle("/business-setup")}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/business-setup"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Setting up an entity in the India
                                          </Link>
                                        </li>
                                      )}
                                      {!isIndia && !isUSA && (
                                        <li className="shli">
                                          <Link
                                            to="/Bussiness-setup(Uae)"
                                            style={linkStyle(
                                              "/Bussiness-setup(Uae)"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/Bussiness-setup(Uae)"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Business Set-up
                                          </Link>
                                        </li>
                                      )}
                                      {!isUAE && (
                                        <li className="shli">
                                          <Link
                                            to="/global-corporate-structuring"
                                            style={linkStyle(
                                              "/global-corporate-structuring"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/global-corporate-structuring"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Global Corporate Structuring
                                          </Link>
                                        </li>
                                      )}

                                      <li className="shli">
                                        <Link
                                          to="/business-plan-preparation"
                                          style={linkStyle(
                                            "/business-plan-preparation"
                                          )}
                                          onMouseEnter={() =>
                                            handleMouseEnter(
                                              "/business-plan-preparation"
                                            )
                                          }
                                          onMouseLeave={handleMouseLeave}
                                        >
                                          <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                          Business Plan Preparation
                                        </Link>
                                      </li>
                                      <li className="shli">
                                        <Link
                                          to="/business-valuation-service"
                                          style={linkStyle(
                                            "/business-valuation-service"
                                          )}
                                          onMouseEnter={() =>
                                            handleMouseEnter(
                                              "/business-valuation-service"
                                            )
                                          }
                                          onMouseLeave={handleMouseLeave}
                                        >
                                          <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                          Business Valuation
                                        </Link>
                                      </li>
                                      <li className="shli">
                                        <Link
                                          to="/merge-and acquisitions"
                                          style={linkStyle(
                                            "/merge-and acquisitions"
                                          )}
                                          onMouseEnter={() =>
                                            handleMouseEnter(
                                              "/merge-and acquisitions"
                                            )
                                          }
                                          onMouseLeave={handleMouseLeave}
                                        >
                                          <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                          Merge and Acquisitions
                                        </Link>
                                      </li>
                                      {!isIndia && !isUSA && (
                                        <li className="shli">
                                          <Link
                                            to="/strategic-cfo-services"
                                            style={linkStyle(
                                              "/strategic-cfo-services"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/strategic-cfo-services"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Strategic CFO Services
                                          </Link>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>

                          {isIndia && (
                            <Accordion.Item eventKey="4">
                              <Accordion.Header>
                                <span style={headerStyle}>
                                  Startup Solution
                                </span>
                              </Accordion.Header>

                              <Accordion.Body>
                                <div
                                  id="collapseSev"
                                  className="collapse show"
                                  aria-labelledby="headingSev"
                                  data-parent="#accordionExample1"
                                >
                                  <div className="card-body bg-white">
                                    <div>
                                      <ul className="text-dark serviceul">
                                        <li className="shli">
                                          <Link
                                            to="/startup-solution"
                                            style={linkStyle(
                                              "/startup-solution"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/startup-solution"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Startup Solution
                                          </Link>
                                        </li>

                                        <li className="shli">
                                          <Link
                                            to="/fund-raising"
                                            style={linkStyle("/fund-raising")}
                                            onMouseEnter={() =>
                                              handleMouseEnter("/fund-raising")
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Fund Raising
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8 ">
              <div
                style={{
                  marginTop: "0rem",
                  fontWeight: "bold",
                  color: "#002249",
                }}
              >
                {!isUAE && (
                  <>
                    <h3
                      style={{
                        fontWeight: "bold",
                        color: "#002249",
                        // paddingLeft: "20px",
                      }}
                    >
                      Accounting and Transaction Processing
                    </h3>
                    <p
                      className="mb-15 mt-20 text-dark servicep"
                      style={{ fontWeight: "400" }}
                    >
                      At Accounting and Financial Solution, we provide
                      comprehensive and tailored accounting solutions to meet
                      the unique needs of our clients. We believe in the
                      importance of effective financial management as the
                      cornerstone of every successful business. Our experienced
                      professionals deliver top-notch Solutions By leveraging
                      our expertise and cutting-edge technology, we streamline
                      processes, reduce costs, and maximize profitability.
                      Experience our personalized approach and let us guide you
                      towards financial success.
                    </p>
                    <p
                      className="mb-15 mt-20 text-dark servicep"
                      style={{ fontWeight: "400" }}
                    >
                      Our services philosophy is rooted in expertise,
                      reliability, and client satisfaction. We understand the
                      complexities of financial management and aim to alleviate
                      the burden for businesses of all sizes. With a team of
                      highly skilled professionals, we stay up to date with
                      industry trends and regulations to provide accurate and
                      compliant financial services. By establishing long-term
                      partnerships, we tailor our services to meet specific
                      needs, such as bookkeeping, payroll management, tax
                      planning, and financial reporting. Partner with us for
                      comprehensive accounting and financial solutions that
                      empower your business's growth and success.
                    </p>
                  </>
                )}

                {!isIndia && !isUSA && (
                  <>
                    <h3
                      style={{
                        fontWeight: "bold",
                        color: "#002249",
                        // paddingLeft: "20px",
                      }}
                    >
                      Accounting & Transaction Processing (UAE)
                    </h3>
                    <p
                      className="mb-15 mt-20 text-dark servicep"
                      style={{ fontWeight: "400" }}
                    >
                      Accounting
                    </p>
                    <p
                      className="mb-15 mt-20 text-dark servicep "
                      style={{ fontWeight: "400" }}
                    >
                      BFAG’s expert team understands the unique accounting
                      requirements of businesses and provides tailored solutions
                      to meet your financial needs. BFAG's Accounting Services
                      include onsite and cloud accounting, accounting review and
                      supervision, as well as audit assistance, ensuring
                      accurate financial management for your organization.
                    </p>

                    <div
                      class="container pt-4"
                      style={{ backgroundColor: "#F0f8ff" }}
                    >
                      <div className="row align-items-start ">
                        <div className="col pt-1 pb-1 ">
                          <Accordion>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                <span style={headerStyle}>
                                  Onsite Accounting
                                </span>
                              </Accordion.Header>
                              <Accordion.Body>
                                <p
                                  style={{
                                    fontWeight: "400",
                                    textAlign: "justify",
                                    color: "#000",
                                  }}
                                >
                                  Our onsite accounting services bring
                                  convenience and efficiency to your business
                                  operations. Our skilled accountants can work
                                  directly at your premises at a desired
                                  frequency, providing real-time support and
                                  handling day-to-day accounting tasks. With our
                                  expertise, you can ensure accurate
                                  record-keeping, timely reporting, and seamless
                                  financial management within your organization.
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                        <div class="col pt-1 pb-1">
                          <Accordion>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>
                                <span style={headerStyle}>
                                  Cloud Accounting
                                </span>
                              </Accordion.Header>
                              <Accordion.Body>
                                <p
                                  style={{
                                    fontWeight: "400",
                                    textAlign: "justify",
                                    color: "#000",
                                  }}
                                >
                                  Embrace the power of technology with our cloud
                                  accounting services. We utilize
                                  industry-leading accounting software platforms
                                  such as TallyPrime, QuickBooks, and Zoho to
                                  provide efficient and secure cloud-based
                                  accounting solutions. This enables you to
                                  access your financial data anytime, anywhere,
                                  facilitating better decision-making and
                                  collaboration within your organization.
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      </div>

                      <div class="row align-items-start">
                        <div class="col pt-1 pb-1">
                          <Accordion>
                            <Accordion.Item eventKey="2">
                              <Accordion.Header>
                                <span style={headerStyle}>
                                  Accounting Review / Supervision Services
                                </span>
                              </Accordion.Header>
                              <Accordion.Body>
                                <p
                                  style={{
                                    fontWeight: "400",
                                    textAlign: "justify",
                                    color: "#000",
                                  }}
                                >
                                  BFAG offers accounting review and supervision
                                  services to ensure the accuracy and integrity
                                  of your financial records. Our experienced
                                  professionals perform regular reviews and
                                  analysis of your accounting processes and
                                  financial statements. We identify areas for
                                  improvement, implement necessary controls, and
                                  provide guidance to enhance your accounting
                                  practices, thereby reducing the risk of errors
                                  or irregularities.
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                        <div class="col pt-1 pb-1">
                          <Accordion>
                            <Accordion.Item eventKey="3">
                              <Accordion.Header>
                                <span style={headerStyle}>
                                  Audit Assistance
                                </span>
                              </Accordion.Header>
                              <Accordion.Body>
                                <p
                                  style={{
                                    fontWeight: "400",
                                    textAlign: "justify",
                                    color: "#000",
                                  }}
                                >
                                  Preparing for an audit can be a complex and
                                  time-consuming process. BFAG provides audit
                                  assistance services to help you navigate
                                  through the audit requirements smoothly. We
                                  work closely with your auditors, ensuring
                                  proper documentation, accurate financial
                                  statements, and compliance with auditing
                                  standards. Our team assists you in addressing
                                  audit queries, resolving issues, and
                                  streamlining the audit process for a
                                  successful outcome.{" "}
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      </div>

                      <br />
                    </div>
                    <p
                      className="mb-15 mt-20 text-dark servicep"
                      style={{ paddingLeft: "20px", fontWeight: "400" }}
                    >
                      We offer comprehensive solutions to meet your
                      organization's financial needs. With our expertise in
                      onsite and cloud accounting, accounting review and
                      supervision, as well as audit assistance, we ensure
                      accurate record-keeping, compliance with accounting
                      standards, and seamless financial management. Trust BFAG
                      to handle your accounting requirements and focus on your
                      core business activities. Contact us today to benefit from
                      our specialized Accounting Services and experience the
                      advantage of professional financial management for your
                      organization.
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountingTransactionProcessing;
