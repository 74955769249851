import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import blog4 from "../../assets/images/blogs/blog4.jfif";

const ThirdBlog = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
       <style jsx>{`
  h4 {
    font-size: 18px;
  }
  h3 {
    font-size: 28px;
  }
  span {
    font-size: 16px;
  }
  p {
    font-size: 16px;
  }
  li {
    font-size: 16px;
    font-weight: 500;
  }
  .mainCard {
    height: 20rem;
  }
  .circle {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
  }
  i {
    color: #072f5f;
  }
  ul {
    padding-left: 17px;
  }
  
  /* Media Query for Mobile Devices */
  @media (max-width: 574px) {
    .mTop {
      margin-top: 30px;
    }
    p {
      font-size: 18px;
    }
    .img-fluid {
      min-height: 100px;
    }
    .container-fluid {
      padding-left: 5px;
      padding-right: 5px;
      width: 100%; /* Ensure it takes full width */
      box-sizing: border-box; /* Include padding in the element’s total width */
    }

    /* Ensure images and other content fit inside the screen */
    img {
      width: 100%;
      height: auto;
      max-width: 100%; /* Prevent images from overflowing */
    }
    /* Add padding to the sides of the content */
    .container-fluid .d-flex {
      padding-left: 5px;
      padding-right: 5px;
    }
    .banner-image {
      margin-top: 70px; /* Adjust this value for mobile view */
    }
    .col-lg-8 {
      padding: 1rem; /* Adjust padding for mobile devices */
    }
  }

  /* Large screens - For larger viewports */
  @media (min-width: 992px) {
    /* for banner image */
    .bmTop {
      margin-top: 130px;
    }
  }
`}</style>


      <section className="">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold", marginTop:'10px' }}
            >
              Navigating Tax Changes in 2024 : <br />
              What You Need to Know
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Income Tax Brackets
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      ></span>
                    </div>
                    <p className="text-dark servicep" style={{textAlign:'justify'}}>
                      The 2024 income tax brackets have been adjusted for
                      inflation. Here’s how they break down: 37%: For single
                      taxpayers with incomes over $609,350 (or $731,200 for
                      married couples filing jointly).
                    </p>
                  </div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      ESG Integration
                    </small>
                  </div>
                  <div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        35% : For single taxpayers with incomes over $243,725
                        (or $487,450 for married couples).
                      </li>
                      <li>
                        32% : For single taxpayers with incomes over $191,950
                        (or $383,900 for married couples).
                      </li>
                      <li>
                        32% : For single taxpayers with incomes over $191,950
                        (or $383,900 for married couples).
                      </li>
                      <li>
                        22%: For single taxpayers with incomes over $47,150 (or
                        $94,300 for married couples).
                      </li>
                      <li>
                        12%: For single taxpayers with incomes over $11,600 (or
                        $23,200 for married couples).
                      </li>
                      <li>
                        12%: For single taxpayers with incomes over $11,600 (or
                        $23,200 for married couples).
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Standard Deduction :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        The standard deduction has increased: Married couples
                        filing jointly: $29,200. Single taxpayers and married
                        individuals filing separately: $14,600. Heads of
                        households: $21,900.
                      </li>

                      <li></li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Retirement Contribution Limits :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        Maximize your retirement savings: 401(k) plan
                        contribution limit: $23,000 (up $500 from 2023).
                      </li>
                      <img
                        src={blog4}
                        style={{
                          display: "block",
                          margin: "1rem auto",
                          width: "350px",
                          height: "270px",
                        }}
                      />
                      <li></li>
                    </ul>
                  </div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Gift Tax Exclusion
                    </small>
                  </div>
                  <br />
                  <p className="text-dark servicep">
                    The gift tax exclusion remains at $15,000 per recipient.
                  </p>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Saver’s Credit
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        The Saver’s Credit is a tax break for low- and
                        middle-income tax filers designed to encourage them to
                        save for retirement. The content with fewer perplexities
                        is better because it avoids the use of clichés and words
                        that have become common due to the use of AI language
                        models such as ChatGPT. For example,-High burstiness
                        which means the content has varied writing patterns and
                        sentence lengths.-High readability which means the
                        content has sentences with short words and low amount of
                        syllables.
                      </li>
                    </ul>
                  </div>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ThirdBlog;
