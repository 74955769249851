import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import blog6 from "../../assets/images/blogs/6.png";
import blog8 from "../../assets/images/blogs/8.png";
import blog9 from "../../assets/images/blogs/9.png";
import blogee4 from "../../assets/images/blogs/blogee4.jpg";

const Auditedfinancialreport = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
      <style jsx>{`
  h4 {
    font-size: 18px;
  }
  h3 {
    font-size: 28px;
  }
  span {
    font-size: 16px;
  }
  p {
    font-size: 16px;
  }
  li {
    font-size: 16px;
    font-weight: 500;
  }
  .mainCard {
    height: 20rem;
  }
  .circle {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
  }
  i {
    color: #072f5f;
  }
  ul {
    padding-left: 17px;
  }
  @media (max-width: 574px) {
    .mTop {
      margin-top: 30px;
    }
    p {
      font-size: 18px;
    }
    .img-fluid {
      min-height: 100px;
    }
    .container-fluid {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @media (min-width: 992px) {
    /* for banner image */
    .bmTop {
      margin-top: 130px;
    }
  }
  @media (max-width: 574px) {
    .banner-image {
      margin-top: 70px; /* Adjust this value for mobile view */
    }
  }
`}</style>

      <section className="">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold", marginTop:'10px' }}
            >
              Meaning of Audited Financial Reports to Businesses
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Introduction
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      ></span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      The age of the century, an age that characterizes high
                      degrees of complexity in financial aspects, high oversight
                      from regulatory agencies, and the importance of audited
                      financial reports has never been clearer. Business
                      concerns in the USA and other regions are beginning to
                      realize their essential role as guardians of transparency,
                      and strategic decision-making.
                    </p>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      Boston Financial Advisory Group helps our clients
                      understand the power of audited financial statements and
                      how they can drive business forward. What are audited
                      financial reports? More than simply a regulatory
                      requirement, an audited financial report is the key to
                      unlocking the ability for businesses of any size to make
                      informed decisions. Why?
                    </p>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Here's why:
                      </span>
                    </div>
                  </div>
                  <ul
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li className="mt-2">
                      Increased Credibility: In the current competitive business
                      environment, it's currency. The independently objective
                      statement of a company's financial situation is prepared
                      by CPAs (audited financial statements).. It can
                      significantly boost a company's reputation with investors,
                      lenders, and partners. In addition, audits often reveal
                      inefficiencies and areas for improvement. This can be very
                      valuable for companies that wish to expand their business
                      operations and improve their financial management.
                    </li>
                    <li className="mt-2">
                      Quality Decisions: Good, clean, audited financial
                      statements provide a solid foundation for making strategic
                      planning decisions. Whether growth, investment, or trying
                      to navigate bad economic periods, having clean financial
                      data is essential.
                    </li>
                    <li className="mt-2">
                      Better Access to Capital: For U.S. companies seeking loans
                      or investors, audited financial statements are invaluable.
                      They give a better representation of the financials. For
                      funding or investment opportunities the investors put
                      reliance on audited financial statements.
                    </li>
                    <li className="mt-4">
                      Compliance: Where regulatory requirements are continuously
                      changing, having up-to-date audited reports ensures your
                      business complies with current standards and laws.
                    </li>
                    <li className="mt-4">
                      Effect on Small Business and Gig Economy: In the past,
                      companies knew that having an audited financial statement
                      of their business would help improve their bottom line.
                    </li>
                    <p>
                      Nowadays, more small businesses and gig economy workers
                      come to understand its advantage.{" "}
                    </p>
                    <li className="mt-4">
                      For Small Businesses Scalability: With the audited
                      financial statements, transitioning a business into a more
                      sizable operation or raising complex finances could be
                      achieved easily.
                    </li>
                    <li className="mt-4">
                      Competitive edge: Audited financial reports give you
                      credibility. Most importantly, having audited financial
                      reports helps identify potential financial risks, allowing
                      management to address them proactively and avoid
                      significant issues.{" "}
                    </li>
                    <li className="mt-4">
                      Simplification of Tax Compliance: Providing clear, audited
                      financial accounts will ease the stress that many gig
                      workers experience during tax season, helping them manage
                      better.{" "}
                    </li>
                    <li className="mt-4">
                      Getting access to finance products: banks, institutions
                      prefer applicants who provide audited financials. Within
                      the very tricky world of tax regulation; audited financial
                      statements make great contributions toward ensuring tax
                      compliance or risk mitigation.{" "}
                    </li>
                  </ul>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Some strategies to consider are:
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                        Proactive Planning: Use your audited financials as the
                        basis for proactive tax planning over the year, not just
                        when it is tax season time.
                      </li>
                      <li className="mt-2">
                        Documentation: Ensure you have adequate documentation
                        regarding your financial statements, so your audit is
                        smoother and the threat of tax issues is minimized.
                      </li>
                      <li className="mt-2">
                        Regular Reviews: Regular review by your CPA ensures
                        you're following current tax law and regulations in your
                        financial activities.
                      </li>
                      <li className="mt-2">
                        Technology Integration: In terms of financial
                        technology, take advantage of record keeping systems and
                        other tools that make the process easier.
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Boston Financial Advisory Group Advantage:
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                        Boston Financial Advisory Group provides top-tier
                        services to businesses and individuals. Our team of
                        professional CPAs is dedicated to guiding you through
                        the maze of financial reporting and auditing.{" "}
                      </li>
                      <li className="mt-2">
                        We provide: Customized services for your business needs,
                        strategic financial advice that will help you in your
                        growth. Cutting-edge tax compliance strategies
                        specialized for small business and gig economy,
                        financial management expertise.{" "}
                      </li>
                    </ul>
                  </div>

                  <div className="mt-15">
                    <span
                      className="text-capitalize"
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "700",
                        color: "#002249",
                      }}
                    >
                      Conclusion :
                    </span>
                  </div>
                  <img
                    src={blog8}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "350px",
                      height: "270px",
                    }}
                  />
                  <div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        As we step into 2025, the value of audited financial
                        reports continues to increase. These reports play a
                        strategic role for every venture from startups to
                        established corporations and gig economy workers
                        striving to identify their niche and serve not only the
                        compliance aspect but also as something rather
                        instrumental in laying down the solid foundation for
                        proper financial control, growth, and successful
                        operations in an ever-evolving economic environment. We
                        offer professional accounting and compliance services
                        designed to improve your financial practices, investment
                        opportunities, and resolve tax complexities. As CPAs,
                        our expertise will be there for every step of the way
                        with you. Don't let financial uncertainty hold your
                        business back. Call Boston Financial Advisory Group
                        today and find out how our services can benefit.
                      </li>
                    </ul>
                  </div>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Auditedfinancialreport;
